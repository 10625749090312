export const tableHeader = [
  {
    id: "name",
    title: "Name",
    sortable: true,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      color: "#99a0aa",
      textTransform: "capitalize",
      textAlign: "left",
    },
    visibility: true,
  },
  {
    id: "email",
    title: "Email",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "role",
    title: "Role",
    sortable: false,
    headerStyle: { textAlign: "left", minWidth: "80px" },
    rowStyle: { textAlign: "left" },
    visibility: true,
  },
  {
    id: "created_by",
    title: "Created By",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    visibility: true,
  },
  {
    id: "last_login_at",
    title: "Last Login At",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "created_at",
    title: "Created On",
    sortable: true,
    headerStyle: { textAlign: "left" },
    rowStyle: { textAlign: "left", whiteSpace: "nowrap" },
    visibility: true,
  },
  {
    id: "expires_on",
    title: "Valid Until",
    sortable: false,
    headerStyle: { textTransform: "capitalize", textAlign: "left" },
    rowStyle: {
      textTransform: "capitalize",
      textAlign: "left",
      whiteSpace: "nowrap",
    },
    visibility: true,
  },
  {
    id: "is_active",
    title: "Status",
    sortable: true,
    headerClass: "text-center",
    rowStyle: { textAlign: "center" },
    visibility: true,
  },
  {
    id: "actions",
    title: "Actions",
    sortable: false,
    headerClass: "text-center",
    rowStyle: { minWidth: "95px", whiteSpace: "nowrap", textAlign: "center" },
    visibility: true,
  },
];
