import { Axios } from "../../../lib/axios";

const userManagementRoute = "/client-user-management";
const getUsersCount = () => {
  return Axios.get(`${userManagementRoute}/count-client-users`);
};

export const UserManagementServices = {
  getUsersCount,
};
