let CLIENT_USER_MANAGEMENT = {
  BREADCUMB: {
    TITLE: "User Management",
  },
  USER_MANAGEMENT_TAB: {
    NORMAL_USER_TAB: "Normal User",
    MAGIC_LINK_USER_TAB: "Magic Link User",
  },
};
export default CLIENT_USER_MANAGEMENT;
