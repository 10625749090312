import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import AclAction from "../Components/ACL/AclAction";

import TemplateCard from "../Components/TemplateCard";
import { VisualizationServices } from "../Services/Visualization";
import { activeMenuTab } from "../Store/Actions/User";
import {
  chartAxisDetails,
  chartData,
  chartType,
  selectedElements,
} from "../Store/Actions/Visualization";
import constants from "../Utils/constants";
import Toast from "../Utils/Toast";
import HelpModal from "./HelpModal";
import DailogNew from "../Components/DailogNew";

import isTBRIUser from "../Utils/isTBRIUser";
import CheckUserRole from "../Utils/CheckUserRole";

import ShareTemplate from "../Containers/ShareTemplate";

class DataVisualization extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: [],
      count: 0,
      pageNo: constants.PAGINATION.DEFAULT_PAGE_NO,
      dailogModal: false,
      dailogModalContent: "",
      dailogModalHeader: "",
      dailogModalConfig: {},
      dailogModalStyleType: "",
      expanded: false,
      lastrowDatacardsCount: 0,
      tabs: [
        {
          id: "all",
          name: "All Visualizations",
          is_selected: true,
          hide_tab: false,
        },
        {
          id: "user",
          name: "My Visualizations",
          is_selected: false,
          hide_tab: false,
        },
        {
          id: "enterprise",
          name: "Enterprise Visualization Collection",
          is_selected: false,
          hide_tab: false,
        },
        {
          id: "tbri",
          name: "TBR Visualization Collection",
          is_selected: false,
          hide_tab: false,
        },
        {
          id: "shared",
          name: "Shared Visualizations",
          is_selected: false,
          hide_tab: false,
        },
      ],
    };
  }
  componentDidMount() {
    this.props.activeMenuTab(
      constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE
    );
    this.props.selectedElements({});
    this.props.chartType("");
    this.props.chartAxisDetails({});
    this.props.setStoreChartData([]);
    let tabs = this.state.tabs;
    if (isTBRIUser()) {
      tabs.map((el) => (el.hide_tab = el.id === "enterprise"));
    } else if (CheckUserRole(constants.ROLES.CLIENT_USER.MEMBER)) {
      tabs.map((el) => {
        el.hide_tab = el.id === "user";
        el.is_selected = el.id === "all";
      });
    }
    this.setState(
      {
        tabs,
      },
      () => {
        this.loadData();
      }
    );
  }
  loadData = () => {
    let selectedTab = this.state.tabs.find((el) => el.is_selected === true);
    let query_type =
      selectedTab.id !== "user" ? "&template_type=" + selectedTab.id : "";
    let url =
      "/visualization_chart/getAll?page=" +
      this.state.pageNo +
      "&page_size=9" +
      query_type;
    this.changeRoute(selectedTab.id);
    VisualizationServices.getTemplates(url)
      .then((res) => {
        if (selectedTab.id === "shared") {
          let newData = [];
          for (let i = 0; i < res.data.rows.length; i++) {
            let tempdata = JSON.parse(
              JSON.stringify(res.data.rows[i].visualization_chart)
            );
            tempdata.shared_users = JSON.parse(
              JSON.stringify(res.data.rows[i].shared_users)
            );
            newData.push(tempdata);
          }
          this.setState({
            data: [...this.state.data, ...newData],
            count: res.data.count,
            lastrowDatacardsCount:
              res.data.rows.length < 9 ? res.data.rows.length : 0,
          });
        } else {
          const updatedArray = [...res.data.rows];
          const existingArray = [...this.state.data];
          updatedArray.forEach((ele) => {
            const index = existingArray.findIndex(
              (val) => val.name === ele.name
            );
            if (index > -1) {
              existingArray[index] = ele;
            } else {
              existingArray.push(ele);
            }
          });
          this.setState({
            data: [...existingArray],
            count: res.data.count,
            lastrowDatacardsCount:
              res.data.rows.length < 9 ? res.data.rows.length : 0,
          });
        }
      })
      .catch((error) => {
        console.error(" error", error);
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handleCreateChart = () => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.VISUALIZATION.CREATE.ROUTE,
        {}
      ),
    });
  };

  changeRoute = (tab) => {
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.DATA_VISUALIZATION.VIEW.ROUTE
      ),
      search: "?selected_tab=" + tab,
    });
  };

  editHandler = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.VISUALIZATION.PREVIEW_WITH_ID.ROUTE,
        { id: id }
      ),
    });
  };

  openShareTemplateModal = (e, data) => {
    e.stopPropagation();
    this.setState({
      sharedTemplateId: data.id,
    });
  };

  closeShareTemplateModal = () => {
    this.setState({
      sharedTemplateId: null,
    });
  };

  // update local state of boards
  // currently handling share and enableDisableComments functionality only...
  updateBoardLocally = (type, data) => {
    let rowDataCards = [];
    let boardindex;

    if (type === constants.VISUALIZATION.ENABLE_DISABLE_COMMENTS) {
      rowDataCards = this.state.data.filter((card) => card.id !== data.id);
      rowDataCards.unshift(data.updatedboard);
    } else if (type === constants.VISUALIZATION.DELETE_BOARD) {
      rowDataCards = this.state.data.filter((card) => card.id !== data.boardId);
    } else {
      rowDataCards = this.state.data.map((card) => {
        if (card.id === data.id) {
          return data.updatedboard;
        }
        return card;
      });
    }
    this.setState({
      data: rowDataCards,
    });
  };

  saveShareTemplateSuccess = (boardId, shared_users) => {
    Toast(constants.VISUALIZATION.SHARE.SUCCESS, "success");
    this.setState(
      {
        sharedTemplateId: null,
      },

      async () => {
        this.loadData();
      }
    );
  };

  dailogModalAccept = (config) => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
    switch (config.type) {
      case "remove_visualization":
        this.removeVisualization(config.id);
        break;
      default:
        break;
    }
  };
  dailogModalDecline = () => {
    this.setState({
      dailogModal: false,
      dailogModalHeader: "",
      dailogModalContent: "",
      dailogModalConfig: {},
    });
  };
  removeVisualization = (id) => {
    VisualizationServices.delteTemplate(id)
      .then((res) => {
        let selectedTab = this.state.tabs.find((el) => el.is_selected === true);
        let query_type =
          selectedTab.id !== "user" ? "&template_type=" + selectedTab.id : "";
        let url =
          "/visualization_chart/getAll?page=" +
          1 +
          "&page_size=" +
          9 * this.state.pageNo +
          query_type;
        VisualizationServices.getTemplates(url)
          .then((res) => {
            this.setState({
              pageNo:
                this.state.data.length % 9 === 1 && this.state.data.length != 1
                  ? this.state.pageNo - 1
                  : this.state.pageNo,
            });
            this.setState({
              data: [...res.data.rows],
              count: this.state.count - 1,
              lastrowDatacardsCount: res.data.rows.length % 9,
            });
          })
          .catch((error) => {
            Toast(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
              "error"
            );
          });
        Toast(
          constants.APPLICATION_ROUTE.VISUALIZATION.TEMPLATE_DELETED,
          "success"
        );
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  handlePage = (input) => {
    this.setState({ pageNo: input }, () => this.loadData());
  };
  handleExpandLess = () => {
    let oldData = this.state.data;
    if (this.state.lastrowDatacardsCount > 0) {
      oldData.splice(-this.state.lastrowDatacardsCount);
      this.setState({ lastrowDatacardsCount: 0 });
    } else {
      oldData.splice(-8);
    }
    this.setState({ rowDatacards: oldData, pageNo: this.state.pageNo - 1 });
    //this.changeRoute()
  };
  removeBoardConfirmationHandler = (event, cardData) => {
    event.preventDefault();
    event.stopPropagation();
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">
          Remove Visualization
        </h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="remove-body">
          <h2 className="font-weight-semibold mb-0">
            {cardData.is_published
              ? constants.DATAVISUALIZATION_TEMPLATE
                  .REMOVE_PUBLISHED_TEMPLATE_CONFIRMATION_MESSAGE
              : constants.DATAVISUALIZATION_TEMPLATE
                  .REMOVE_TEMPLATE_CONFIRMATION_MESSAGE}
          </h2>
        </div>
      </>
    );
    this.setState({
      dailogModal: true,
      dailogModalHeader: dailogModalHeader,
      dailogModalContent: dailogModalContent,
      dailogModalConfig: { type: "remove_visualization", id: cardData.id },
      dailogModalStyleType: "danger-modal",
    });
  };

  goToPreviewHandler = (event, id) => {
    event.preventDefault();
    event.stopPropagation();
    this.props.history.push({
      pathname: formatRoute(
        constants.APPLICATION_ROUTE.VISUALIZATION.PREVIEW_WITH_ID.ROUTE,
        { id: id }
      ),
    });
  };
  handleNavTabChange = (id) => {
    let tabs = this.state.tabs;
    tabs.forEach((el) => (el.is_selected = el.id === id));
    this.setState(
      {
        tabs,
        data: [],
        pageNo: 1,
      },
      () => {
        this.loadData();
      }
    );
  };

  render() {
    return (
      <aside className="main_content">
        <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
          <div className="title-section d-flex">
            <div className="beta-label">Beta</div>
            <h1 className="text-ligntblue2 font-weight-bold mb-0">
              Data Visualization
            </h1>
            {isTBRIUser() && (
              <>
                <div className="ml-auto d-flex ">
                  <AclAction
                    type={constants.ACL.PERMISSION_KEY}
                    entity={constants.ACL.MY_VISUALIZATION_RESOURCE.TITLE}
                    action={
                      constants.ACL.MY_VISUALIZATION_RESOURCE.ACTIONS.CREATE
                    }
                  >
                    <aside
                      tbrentitytype={constants.ACL.ENTITY_TYPE.BUTTON}
                      className="text-left"
                    >
                      <button
                        className={
                          "btn  btn-md d-inline-flex align-items-center btn-primary mr-2"
                        }
                        onClick={this.handleCreateChart}
                        type="button"
                      >
                        <span className="material-icons-round icon-fs-14 mr-1">
                          add
                        </span>
                        Create Chart
                      </button>
                    </aside>
                  </AclAction>
                </div>
                <HelpModal category="Data Visualization List" />
              </>
            )}
          </div>
          {isTBRIUser() && (
            <>
              <div className="btn-items-card-wrapper mb-3">
                <div
                  className={"card custom-card segments-card btn-items-card"}
                >
                  <div className="card-body">
                    <div className="d-flex">
                      <ul className="segment-items btn-items d-flex flex-wrap">
                        {this.state.tabs.map(
                          (list, index) =>
                            !list.hide_tab && (
                              <li
                                className={`${
                                  list.is_selected === true ? "active" : ""
                                }`}
                              >
                                <div
                                  className={`content font-weight-semibold d-flex align-items-start `}
                                  onClick={() => {
                                    this.handleNavTabChange(list.id);
                                  }}
                                >
                                  <span>{list.name}</span>
                                </div>
                              </li>
                            )
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>

              <div className="comapnay_tbl">
                <div className="card my-boards-wrapper-card">
                  <div className="card-body my-borads-card-body">
                    <div className="row xl-gutter">
                      {this.state.data.map((t, i) => (
                        <div className={`col-12 col-sm-6 col-lg-4 mb-3`}>
                          <TemplateCard
                            template={t}
                            shareHandler={this.openShareTemplateModal}
                            editHandler={this.editHandler}
                            deleteHandler={this.removeBoardConfirmationHandler}
                            detailsPageHandler={this.goToPreviewHandler}
                          />
                        </div>
                      ))}

                      {this.state.sharedTemplateId && (
                        <ShareTemplate
                          boardId={this.state.sharedTemplateId}
                          boardDetails={this.state.data.find(
                            (b) => b.id === this.state.sharedTemplateId
                          )}
                          closeShareBoardModal={this.closeShareTemplateModal}
                          saveShareBoardSuccess={this.saveShareTemplateSuccess}
                        />
                      )}

                      <DailogNew
                        isOpen={this.state.dailogModal}
                        accept={this.dailogModalAccept}
                        decline={this.dailogModalDecline}
                        header={this.state.dailogModalHeader}
                        content={this.state.dailogModalContent}
                        config={this.state.dailogModalConfig}
                        modalStyleType={this.state.dailogModalStyleType}
                      />
                      {this.state.data && this.state.data.length === 0 && (
                        <div className={"col-sm-12 text-center no-boards"}>
                          <span className="material-icons-round mr-2 icon-fs-18">
                            report_problem
                          </span>
                          <h5>
                            {
                              constants.APPLICATION_ROUTE.DATA_VISUALIZATION
                                .VIEW.NO_TEMPLATE_FOUND
                            }
                          </h5>
                        </div>
                      )}
                    </div>
                    <div className="d-flex justify-content-center align-items-center">
                      {this.state.data.length > 9 && (
                        <div className="see-more-btn mx-4 px-2 d-flex flex-column align-items-center justify-content-center">
                          <div
                            className="d-flex justify-content-center cursor-pointer flex-column align-items-center"
                            onClick={() => this.handleExpandLess()}
                          >
                            <h3 className="text-ligntgrey mb-0">See Less</h3>
                            <span className="material-icons-round text-darkblue">
                              expand_less
                            </span>
                          </div>
                        </div>
                      )}

                      {this.state.data.length != 0 &&
                        this.state.data.length != this.state.count && (
                          <div className="see-more-btn mx-4 px-2 d-flex flex-column align-items-center justify-content-center">
                            <div
                              className="d-flex justify-content-center cursor-pointer flex-column align-items-center"
                              onClick={() => {
                                this.handlePage(this.state.pageNo + 1);
                              }}
                            >
                              <h3 className="text-ligntgrey mb-0">See More</h3>
                              <span className="material-icons-round text-darkblue">
                                expand_more
                              </span>
                            </div>
                          </div>
                        )}
                    </div>
                  </div>
                </div>
              </div>
            </>
          )}

          {!isTBRIUser() && (
            <span
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                height: "70vh",
                fontSize: "24px",
              }}
            >
              Coming Soon!
            </span>
          )}
        </div>
      </aside>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
    selectedElements: (obj) => {
      dispatch(selectedElements(obj));
    },
    chartType: (type) => {
      dispatch(chartType(type));
    },
    chartAxisDetails: (obj) => {
      dispatch(chartAxisDetails(obj));
    },
    setStoreChartData: (data) => {
      dispatch(chartData(data));
    },
  };
};

export default connect(() => ({}), mapDispatchToProps)(DataVisualization);
