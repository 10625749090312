import React, { useEffect, useState } from "react";
import ReactGA from "react-ga4";
import React_GA_Intialise from "../../Utils/InitializeGa";
import { formatRoute } from "react-router-named-routes";
import { ButtonToggle, Container, Form, Row } from "reactstrap";

import logo from "../../Images/INSIGHTCENTER@2x.png";
import login_building from "../../Images/login_building.png";
import login_map from "../../Images/login_map.png";
import { MagicLinkUserServices } from "./Services/MagicLinkUserServices";
import Toast from "../../Utils/Toast";
import constants from "../../Utils/constants";
import { useHistory } from "react-router-dom";
import Notification from "../../Components/Notification";
import { useLocation } from "react-router-dom";
import decodeJWT from "../../lib/decodeJWT";
import { FeedBackService } from "../../Services/FeedBack";
import {
  loginUserFailure,
  loginUserSuccess,
  openSearchBar,
} from "../../Store/Actions/User";
import {
  downloadFileDetails,
  downloadingProgressId,
  downloadingProgressModel,
} from "../../Store/Actions/DownloadProgress";
import { connect } from "react-redux";

const MagicLinkVerification = (props) => {
  const history = useHistory();
  const location = useLocation();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(true);
  const [notificationColor, setNotificationColor] = useState("danger");

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");

    if (!token) {
      setLoading(false);
      return;
    }

    // Remove the token from the URL without re-triggering the effect
    history.replace({ search: params.toString() });

    (async () => {
      // Verify token
      await verifyMagicLink(token);
    })();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const verifyMagicLink = async (token) => {
    try {
      setLoading(true);
      const body = { token };
      MagicLinkUserServices.magicLinkLogin(body)
        .then(async (data) => {
          const tokenDecoded = decodeJWT(data.data.access_token);
          localStorage.setItem("clientId", tokenDecoded.client.id);
          localStorage.setItem("token", data.data.access_token);
          localStorage.setItem("refresh_token", data.data.refresh_token);
          localStorage.setItem(
            "display_name",
            tokenDecoded.user.display_name ?? tokenDecoded.user.first_name
          );
          props.loginSuccess(tokenDecoded.user);
          props.downloadingProgressModel(false);
          props.downloadingProgressId(null);
          props.downloadFileDetails({});

          let feedBackFromRes = await FeedBackService.showFeedBack();
          if (feedBackFromRes.data.data) {
            localStorage.setItem(
              "show_feedback_form",
              feedBackFromRes?.data?.data
            );
          }

          // check if he is admin
          // set the initialisation with user I'd
          let is_admin = tokenDecoded.user.is_admin;

          if (!is_admin) {
            React_GA_Intialise(tokenDecoded.user.id);
            ReactGA.event(constants.GA.CUSTOM_EVENTS.LOGIN);
          }

          const logindata = props?.home?.loginUserSuccess;

          const tokenDetails = decodeJWT(localStorage.getItem("token"));
          const urls = [
            "test.tbrinsightcenter.com",
            "localhost",
            "staging.tbrinsightcenter.com",
          ];
          if (!urls.includes(window.location.hostname)) {
            window.Produktly.identifyUser(logindata?.id, {
              name: `${logindata?.first_name} ${logindata?.last_name}`,
              email: logindata?.email,
              companyId: tokenDetails?.client?.name,
            });
          }

          const searchParams = new URLSearchParams(document.location.search);
          if (searchParams.get("redirect_url")) {
            window.location.href = searchParams.get("redirect_url");
          } else {
            history.push(
              formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})
            );
          }
        })
        .catch((error) => {
          // Extract error message from response
          const errorMessage = error?.data?.error || "Invalid magic link.";

          // Update the state to show the error
          setError(errorMessage);
        });
    } catch (err) {
      Toast("Getting error while fetching ");
    }
    setLoading(false);
  };

  const handleRequestNowLink = () => {
    const params = new URLSearchParams(location.search);
    const token = params.get("token");
    MagicLinkUserServices.requestMagicLink(token)
      .then((res) => {
        const response = res.data.result;
        const responseMsg = response.message;
        setError(responseMsg);
        setNotificationColor("success");
      })
      .catch((error) => {
        // Extract error message from response
        const errorMessage = error?.data?.error;
        // Update the state to show the error
        setError(errorMessage);
      });
  };

  return (
    <div className="wrapper login_page">
      <main>
        <Container fluid>
          <Row>
            <aside className="col-md-6 px-0 hide-in-mobille">
              <div className="login_left">
                <figure className="login_banner">
                  <img
                    src={login_building}
                    className="img-fluid"
                    alt="login banner"
                    title="login banner"
                  />
                </figure>
                <a href="/">
                  <img
                    style={{ width: "120px", marginLeft: "20px" }}
                    src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                    alt="TBR logo"
                  />
                </a>
              </div>
            </aside>
            <aside className="col-md-6 px-0">
              <div className="login_right">
                <figure>
                  <img src={login_map} className="img-fluid" alt="map Banner" />
                </figure>
                <Form>
                  <span className="login_formlogos">
                    <img src={logo} className="img-fluid" alt="logo" />
                  </span>
                  {error && (
                    <>
                      <Notification
                        color={notificationColor}
                        message={error}
                        className="mt-2"
                      />
                      <div>
                        <ButtonToggle
                          color="primary"
                          type="button"
                          style={{
                            minWidth: "100px",
                            marginLeft: "20px",
                            maxHeight: "30px",
                            fontSize: "14px",
                          }}
                          onClick={(e) => {
                            e.preventDefault();
                            history.push(
                              formatRoute(
                                constants.APPLICATION_ROUTE.LOGIN.ROUTE,
                                {}
                              )
                            );
                          }}
                          className="d-inline-flex align-items-center justify-content-center"
                        >
                          <span className="material-icons-round icon-fs-14 mr-1">
                            west
                          </span>
                          Back to Login
                        </ButtonToggle>
                        <ButtonToggle
                          color="primary"
                          type="button"
                          style={{
                            minWidth: "100px",
                            marginLeft: "27px",
                            maxHeight: "30px",
                            fontSize: "14px",
                          }}
                          onClick={() => {
                            handleRequestNowLink();
                          }}
                          className="d-inline-flex align-items-center justify-content-center"
                        >
                          Request Now
                        </ButtonToggle>
                      </div>
                    </>
                  )}
                </Form>
                <div className="terms-conditions">
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                    target="_blank"
                  >
                    Terms and Conditions
                  </a>{" "}
                  |
                  <a
                    className="links"
                    href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                    target="_blank"
                  >
                    Privacy Policy
                  </a>
                </div>
              </div>
            </aside>
          </Row>
        </Container>
      </main>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
    downloadFileDetails: (data) => {
      dispatch(downloadFileDetails(data));
    },
    downloadingProgressModel: (data) => {
      dispatch(downloadingProgressModel(data));
    },
    downloadingProgressId: (data) => {
      dispatch(downloadingProgressId(data));
    },
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MagicLinkVerification);
