import React, { Component } from "react";
import { connect } from "react-redux";
import { formatRoute } from "react-router-named-routes";
import {
  Button,
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";

import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import { UserServices } from "../Services/User";
import {
  loginUserFailure,
  loginUserSuccess,
  openSearchBar,
} from "../Store/Actions/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";
import ReactGA from "react-ga4";
import React_GA_Intialise from "../Utils/InitializeGa";
import decodeJWT from "../lib/decodeJWT.js";
import { FeedBackService } from "../Services/FeedBack.js";
import {
  downloadFileDetails,
  downloadingProgressId,
  downloadingProgressModel,
} from "../Store/Actions/DownloadProgress.js";
import Collapsible from "react-collapsible";

class Login extends Component {
  constructor(props) {
    super(props);
    this.getUserInfo = this.getUserInfo.bind(this);
  }
  state = {
    loginEmail: "",
    loginPassword: "",
    apiErrors: "",
    sso_flag: false,
  };

  componentDidMount() {
    DocumentTitle("Login");
    this.handleCallback();
  }

  async getUserInfo(input) {
    localStorage.removeItem("show_feedback_form");
    UserServices.getUserLogin(input)
      .then(async (data) => {
        localStorage.clear();
        const tokenDecoded = decodeJWT(data.data.access_token);
        localStorage.setItem("clientId", tokenDecoded.client.id);
        localStorage.setItem("token", data.data.access_token);
        localStorage.setItem("refresh_token", data.data.refresh_token);
        localStorage.setItem(
          "display_name",
          tokenDecoded.user.display_name ?? tokenDecoded.user.first_name
        );
        this.props.loginSuccess(tokenDecoded.user);
        this.props.downloadingProgressModel(false);
        this.props.downloadingProgressId(null);
        this.props.downloadFileDetails({});

        let feedBackFromRes = await FeedBackService.showFeedBack();
        if (feedBackFromRes.data.data) {
          localStorage.setItem(
            "show_feedback_form",
            feedBackFromRes?.data?.data
          );
        }

        // check if he is admin
        // set the initialisation with user I'd
        let is_admin = tokenDecoded.user.is_admin;

        if (!is_admin) {
          React_GA_Intialise(tokenDecoded.user.id);
          ReactGA.event(constants.GA.CUSTOM_EVENTS.LOGIN);
        }

        const logindata = this.props?.home?.loginUserSuccess;

        const tokenDetails = decodeJWT(localStorage.getItem("token"));
        const urls = [
          "test.tbrinsightcenter.com",
          "localhost",
          "staging.tbrinsightcenter.com",
        ];
        if (!urls.includes(window.location.hostname)) {
          window.Produktly.identifyUser(logindata?.id, {
            name: `${logindata?.first_name} ${logindata?.last_name}`,
            email: logindata?.email,
            companyId: tokenDetails?.client?.name,
          });
        }

        const searchParams = new URLSearchParams(document.location.search);
        if (searchParams.get("redirect_url")) {
          window.location.href = searchParams.get("redirect_url");
        } else {
          this.props.history.push(
            formatRoute(constants.APPLICATION_ROUTE.DASHBOARD_LIST.ROUTE, {})
          );
        }
      })
      .catch((error) => {
        if (
          error &&
          error?.data &&
          error?.data?.error_detail === "Error: Invalid user!!!"
        ) {
          this.setState({ sso_flag: true });
        } else {
          this.setState({
            apiErrors:
              error && error.data && error.data.error_detail
                ? error.data.error_detail
                : error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        }
        this.props.history.push(constants.APPLICATION_ROUTE.LOGIN.ROUTE);
      });
  }

  handleCallback = async () => {
    // Extract query parameters from the URL
    const params = new URLSearchParams(window.location.search);
    const code = params.get("code");
    const error_description = params.get("error_description"); // Get the 'code' parameter from the URL (used for Google OAuth flow)

    if (code) {
      // Close the search bar in the UI
      this.props.openSearchBar(false);
      // Fetch and store user information using the data received from the backend
      let input = {
        code,
        redirect_uri: window.location.origin + "/login",
        samlEmail: localStorage.getItem("samlEmail"),
      };
      await this.getUserInfo(input);
    }
    if (error_description) {
      this.setState({ apiErrors: error_description });
      this.props.history.push(constants.APPLICATION_ROUTE.LOGIN.ROUTE);
    }
  };

  handleSubmit = async (e) => {
    e.preventDefault();
    this.props.openSearchBar(false);
    let input = {
      email: this.state.loginEmail,
      password: this.state.loginPassword,
    };
    await this.getUserInfo(input);
  };
  handleSingleSignOn = async (e) => {
    e.preventDefault();
    this.props.history.push(
      formatRoute(constants.APPLICATION_ROUTE.SSO.ROUTE, {})
    );
  };
  handleInputChange = (e) => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };

  loginWithSso = (e, provider) => {
    e.preventDefault();
    window.location.href = `${
      process.env.REACT_APP_TBRI_OAUTH_REDIRECT_URL
    }?redirect_uri=${window.location.origin + "/login"}&provider=${provider}`;
  };
  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href="/">
                    <img
                      style={{ width: "120px", marginLeft: "20px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                      alt="TBR logo"
                    />
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form>
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.sso_flag ? (
                      <div className="invalid_email_div">
                        <span className="span_1">
                          This account does not exist.
                        </span>
                        <span className="span_2">
                          Apologies, but it appears that this account does not{" "}
                          <br />
                          exist in our system.
                        </span>
                        <Button
                          className="btn_1"
                          onClick={(e) => this.loginWithSso(e, "Google")}
                        >
                          Continue with other mail
                        </Button>
                        <Button
                          className="btn_2"
                          onClick={() => this.setState({ sso_flag: false })}
                        >
                          Try another options
                        </Button>
                      </div>
                    ) : (
                      <>
                        <span className="login_text_span">Log In</span>
                        {this.state.apiErrors.length > 0 && (
                          <Notification
                            color={"danger"}
                            message={this.state.apiErrors}
                            className="mt-2"
                          />
                        )}
                        <FormGroup
                          style={{ marginBottom: "35px", marginTop: "30px" }}
                        >
                          <Label htmlFor="loginEmail">Email</Label>
                          <Input
                            type="email"
                            placeholder="Email"
                            id="loginEmail"
                            name="loginEmail"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <Label htmlFor="loginPassword">Password</Label>
                          <Input
                            type="password"
                            placeholder="Password"
                            id="loginPassword"
                            name="loginPassword"
                            onChange={this.handleInputChange}
                          />
                        </FormGroup>
                        <div
                          // className="text-center"
                          style={{
                            marginBottom: "20px",
                            display: "flex",
                            justifyContent: "flex-end",
                            marginTop: "10px",
                          }}
                        >
                          <a href="/forgot-password">Forgot Password ?</a>
                        </div>
                        <FormGroup style={{ marginBottom: "0px" }}>
                          <ButtonToggle
                            className={
                              this.state.loginEmail && this.state.loginPassword
                                ? ""
                                : "disabled"
                            }
                            style={{ minWidth: "100%" }}
                            disabled={
                              !(
                                this.state.loginEmail &&
                                this.state.loginPassword
                              )
                            }
                            color="primary"
                            type="submit"
                            onClick={this.handleSubmit}
                          >
                            Submit
                          </ButtonToggle>
                        </FormGroup>

                        <div className="sso_div">
                          <span>Or</span>
                          <div>
                            <Button
                              className="w-100"
                              style={{
                                fontWeight: 400,
                                fontSize: "16px",
                                background: "transparent",
                              }}
                              type="submit"
                              onClick={this.handleSingleSignOn}
                            >
                              Sign in with Single Sign-On
                            </Button>{" "}
                          </div>
                        </div>

                        <div className="container mt-3 p-0">
                          <Collapsible
                            trigger="Additional Sign-In Options"
                            triggerClassName="btn btn-link"
                            triggerStyle={{ color: "white", fontSize: "16px" }}
                            transitionTime={200}
                            className="text-white"
                          >
                            <div className="mt-3 d-flex justify-content-between">
                              <button
                                className="btn btn-outline-light border "
                                onClick={(e) => this.loginWithSso(e, "Google")}
                                style={{ fontSize: "14px" }}
                              >
                                <img
                                  src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/GoogleLogo.svg"
                                  alt="Google Logo"
                                  width="20"
                                  className="mr-2"
                                />
                                Sign in with Google
                              </button>
                              <button
                                className="btn btn-outline-light border "
                                onClick={(e) => this.loginWithSso(e, "O365")}
                                style={{ fontSize: "14px" }}
                              >
                                <img
                                  src="https://tbr-ggk.s3.us-east-2.amazonaws.com/development+/MicrosoftLogo.svg"
                                  alt="Microsoft Logo"
                                  width="20"
                                  className="mr-2"
                                />
                                Sign in with Microsoft
                              </button>
                            </div>
                          </Collapsible>
                        </div>
                      </>
                    )}
                  </Form>
                  <div className="terms-conditions">
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Terms+of+Service.pdf"
                      target="_blank"
                    >
                      Terms and Conditions
                    </a>{" "}
                    |
                    <a
                      className="links"
                      href="https://tbrdevfiles.s3.amazonaws.com/documents/TBR+Privacy+Policy.pdf"
                      target="_blank"
                    >
                      Privacy Policy
                    </a>
                  </div>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};

function mapDispatchToProps(dispatch) {
  return {
    loginSuccess: (user) => {
      dispatch(loginUserSuccess(user));
    },
    loginFailure: (user) => {
      dispatch(loginUserFailure(user));
    },
    openSearchBar: (data) => {
      dispatch(openSearchBar(data));
    },
    downloadFileDetails: (data) => {
      dispatch(downloadFileDetails(data));
    },
    downloadingProgressModel: (data) => {
      dispatch(downloadingProgressModel(data));
    },
    downloadingProgressId: (data) => {
      dispatch(downloadingProgressId(data));
    },
  };
}
export default connect(mapStateToProps, mapDispatchToProps)(Login);
