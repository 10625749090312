import { Axios } from "../lib/axios";

export const CalendarServices = {
  getCalendarEvent,
  createCalendarEvent,
  updateCalendarEvent,
  deleteCalendarEvent,
  getSearchData,
  getPublishedCalendarEvent,
  getUpcomingEvent,
};

function getCalendarEvent(status) {
  let url = "/events";
  if (status) {
    url = `${url}?${status}`;
  }
  return Axios.get(url);
}

function getPublishedCalendarEvent(status) {
  let url = "/calenderEvents";
  if (status) {
    url = `${url}?${status}`;
  }
  return Axios.get(url);
}

function createCalendarEvent(body) {
  return Axios.post("/addEvent", body);
}

function updateCalendarEvent(id, body) {
  return Axios.put(`/event/${id}`, body);
}

function deleteCalendarEvent(id) {
  return Axios.delete(`/event/${id}`);
}

function getSearchData(params) {
  return Axios.get(`/events?${params}`);
}

function getUpcomingEvent() {
  return Axios.get("/upcomingCalendarEvents");
}
