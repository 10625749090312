import React, { useEffect, useState } from "react";
import DailogNew from "../../Components/DailogNew";
import constants from "../../Utils/constants";
import SamlModalContent from "./SamlModalContent";
import { isEmpty } from "lodash";
import { SamlServices } from "../../Services/SamlServices";
import Toast from "../../Utils/Toast";
import decodeJWT from "../../lib/decodeJWT";
import SAML_CONSTANTS from "./utils/constants";
import { UserServices } from "../../Services/User";
import VideoModal from "../../Components/VideoModal";

const SamlConfiguration = ({ mode, open, close }) => {
  const tokenDetails = decodeJWT(localStorage.getItem("token"));
  const clientID = tokenDetails.client?.id;
  const [modalState, setModalState] = useState({
    dailogModal: false,
    dailogModalHeader: null,
    dailogModalConfig: {
      type: SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS,
    },
    dailogModalStyleType: "ssosaml-modal-size",
    dailogModalContent: <></>,
    submitButtonLabel: "Confirm",
  });

  const [formErrors, setFormErrors] = useState({});
  const [ssosamlConfigValues, setssosamlConfigValues] = useState({
    entityId: "",
    replayUrl: "",
    metadataURL: "",
    email: "",
    givenName: "",
    name: "",
  });

  const [videoModalStates, setVideoModalStates] = useState({
    isVideoModalOpen: false,
    title: "",
    videoUrl: "",
  });

  // Fetch SAML Config Data when `open` is true
  useEffect(() => {
    if (!open) return;

    SamlServices.getSAMLUrls()
      .then((res) => {
        setssosamlConfigValues((prev) => ({
          ...prev,
          entityId: res?.data?.entity_id || "",
          replayUrl: res?.data?.replay_url || "",
        }));
      })
      .catch((error) => {
        Toast(
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  }, [open]);

  useEffect(() => {
    if (
      open &&
      mode === SAML_CONSTANTS.CONFIGURATION_SAML.MODE.EDIT_SAML_DETAILS
    ) {
      SamlServices.fetchIDPSamlConfiguration(clientID).then((res) => {
        const responseData = res.data.cognitoResponse;
        const mapping_attributes = JSON.parse(responseData.mapping_attributes);

        setssosamlConfigValues({
          metadataURL: responseData?.metadata_url || "",
          email: mapping_attributes?.email || "",
          givenName: mapping_attributes?.given_name || "",
          name: mapping_attributes?.name || "",
        });
      });
    }
  }, [open, mode, clientID]);

  // Validate fields and return error messages
  const validateField = (field, value) => {
    if (field === "metadataURL") {
      if (isEmpty(value.trim())) {
        return "Please fill this field.";
      } else if (!constants.REG_EX.HTTPS_LINK.test(value.trim())) {
        return "Invalid URL format.";
      }
    } else if (field === "email") {
      if (isEmpty(value.trim())) {
        return "Email is required.";
      }
    } else if (field === "givenName") {
      if (isEmpty(value.trim())) {
        return "Given Name is required.";
      }
    } else if (field === "name") {
      if (isEmpty(value.trim())) {
        return "Name is required.";
      }
    }
    return ""; // No error
  };

  // Handle form updates and trigger revalidation
  const handleSamlData = (updatedValues) => {
    setssosamlConfigValues((prev) => {
      const newValues = { ...prev, ...updatedValues };
      return newValues;
    });

    // Validate and update errors for each changed field
    Object.entries(updatedValues).forEach(([field, value]) => {
      const errorMessage = validateField(field, value);
      setFormErrors((prev) => ({
        ...prev,
        [field]: errorMessage,
      }));
    });
  };

  const openVideoModal = (provider) => {
    const videoData = {
      azure: {
        url: "https://tbr-ggk.s3.us-east-2.amazonaws.com/production/Azure_SAML_setup.mp4",
        title: "Azure SAML Configuration Reference",
      },
      google: {
        url: "https://tbr-ggk.s3.us-east-2.amazonaws.com/production/Google_SAML_setup.mp4",
        title: "Google SAML Configuration Reference",
      },
    };

    setVideoModalStates({
      isVideoModalOpen: true,
      videoUrl: videoData[provider].url,
      title: videoData[provider].title,
    });
  };

  // Use useEffect to update modal content when form values or errors change
  useEffect(() => {
    if (!open) return;

    setModalState((prev) => ({
      ...prev,
      dailogModal: true,
      dailogModalHeader: (
        <div className="d-flex align-items-center">
          <h3 className="text-darkblue2 font-weight-bold">
            {mode === SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
              ? SAML_CONSTANTS.CONFIGURATION_SAML.DAILOG.ENABLE_HEADER_TITLE
              : SAML_CONSTANTS.CONFIGURATION_SAML.DAILOG.VIEW_HEADER_TITLE}
          </h3>
          <h3 className="ml-2">
            <div className="d-flex align-items-center justify-content-center">
              <span
                className="azure-reference material-icons-outlined mr-1 icon-fs-18"
                style={{ color: "red" }}
              >
                play_circle
              </span>
              <span
                className="text-darkblue2 font-weight-bold fs-12"
                style={{ textDecoration: "underline", cursor: "pointer" }}
                onClick={() => openVideoModal("azure")}
              >
                View Azure reference
              </span>

              {/* <span
              className="google-reference material-icons-outlined mr-1 icon-fs-18 ml-2"
              style={{ color: "red" }}
            >
              play_circle
            </span>
            <span
              className="text-darkblue2 font-weight-bold fs-12 ml-2"
              style={{ textDecoration: "underline", cursor: "pointer" }}
              onClick={openVideoModal("google")}
            >
              View Google reference
            </span> */}
            </div>
          </h3>
        </div>
      ),
      dailogModalContent: (
        <SamlModalContent
          handleSamlData={handleSamlData}
          formErrors={formErrors}
          ssosamlConfigValues={ssosamlConfigValues}
        />
      ),
      dailogModalConfig: {
        type:
          mode === SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
            ? SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
            : SAML_CONSTANTS.CONFIGURATION_SAML.MODE.EDIT_SAML_DETAILS,
      },
      dailogModalStyleType: "ssosaml-modal-size",
      submitButtonLabel: "Verify",
    }));
  }, [mode, open, ssosamlConfigValues, formErrors]);

  // Validate Entire Form Before Submission
  const isSSOSAMLConfigValidated = () => {
    const errors = {};
    let isValid = true;

    // Check each field and collect all errors
    const fieldsToValidate = ["metadataURL", "email", "givenName", "name"];
    fieldsToValidate.forEach((field) => {
      const errorMessage = validateField(field, ssosamlConfigValues[field]);
      if (errorMessage) {
        errors[field] = errorMessage;
        isValid = false;
      }
    });

    // Update the form errors state
    setFormErrors(errors);

    return isValid;
  };

  // Verify SAML modal
  const verifySamlModal = (type) => {
    if (isSSOSAMLConfigValidated()) {
      VerifyMetaDataUrl(type);
    }
  };

  const editSamlModal = (type) => {
    if (isSSOSAMLConfigValidated()) {
      VerifyMetaDataUrl(type);
    }
  };

  const VerifyMetaDataUrl = (type) => {
    const metadata_url = ssosamlConfigValues.metadataURL;
    let body = { metadata_url };

    SamlServices.verifyMetaDataURL(body)
      .then((res) => {
        if (res.status === 200) {
          setModalState({
            dailogModal: true,
            dailogModalHeader: (
              <h3 className="text-darkblue2 font-weight-bold d-flex align-items-center justify-content-center">
                {type ===
                SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
                  ? "Verify!"
                  : "Are you sure?"}
              </h3>
            ),
            dailogModalConfig: {
              type:
                type ===
                SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
                  ? "added_saml"
                  : "edited_saml",
            },
            dailogModalStyleType: "success-modal",
            dailogModalContent: (
              <h3
                className="text-darkblue2 font-weight-bold"
                style={{ fontSize: "14px" }}
              >
                {type ===
                SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS
                  ? "Your metadata has been successfully verified. Would you like to proceed ahead?"
                  : "Your meta URL is successfully verified! Are you sure you want to save this edit? Would you like to proceed ahead?"}
              </h3>
            ),
            submitButtonLabel: "Confirm",
          });
        } else {
          Toast("Your metadata URL is wrong, not verified", "error");
        }
      })
      .catch((error) => {
        Toast(
          error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const addSamalDetails = () => {
    const metadata_url = ssosamlConfigValues.metadataURL;
    const mappingAttributes = {
      email: ssosamlConfigValues?.email || "",
      given_name: ssosamlConfigValues?.givenName || "",
      name: ssosamlConfigValues?.name || "",
    };

    let saml_configuration = {
      metadata_url,
      mapping_attributes: JSON.stringify(mappingAttributes),
    };

    SamlServices.addIDPSamlConfiguration(saml_configuration)
      .then(() => {
        setModalState({
          dailogModal: false,
          dailogModalHeader: null,
          dailogModalConfig: { type: "" },
          dailogModalStyleType: "",
        });
        Toast(SAML_CONSTANTS.CONFIGURATION_SAML.ADD, "success");
        localStorage.setItem("client_verification_status", true);
        close();
      })
      .catch((error) => {
        Toast(
          error?.data?.error_detail || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };
  const getRefreshToken = () => {
    UserServices.getTokenFromRefreshToken()
      .then((res) => {
        localStorage.setItem("token", res.data.token.access_token);
        localStorage.setItem("refresh_token", res.data.token.refresh_token);
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const updateSamlDetails = (type) => {
    const metadata_url = ssosamlConfigValues.metadataURL;
    const mappingAttributes = {
      email: ssosamlConfigValues?.email || "",
      given_name: ssosamlConfigValues?.givenName || "",
      name: ssosamlConfigValues?.name || "",
    };

    let saml_configuration = {
      metadata_url,
      mapping_attributes: JSON.stringify(mappingAttributes),
    };

    SamlServices.updateIDPSamlConfiguration(saml_configuration)
      .then(() => {
        setModalState({
          dailogModal: false,
          dailogModalHeader: null,
          dailogModalConfig: { type: "" },
          dailogModalStyleType: "",
        });
        Toast(SAML_CONSTANTS.CONFIGURATION_SAML.UPDATE, "success");
        close();
      })
      .catch((error) => {
        Toast(
          error?.data?.error_detail || constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const dailogModalAccept = (config) => {
    switch (config.type) {
      case SAML_CONSTANTS.CONFIGURATION_SAML.MODE.SETUP_SAML_DETAILS:
        verifySamlModal(config.type);
        break;
      case SAML_CONSTANTS.CONFIGURATION_SAML.MODE.EDIT_SAML_DETAILS:
        editSamlModal(config.type);
        break;
      case "added_saml":
        addSamalDetails(config.type);
        break;
      case "edited_saml":
        updateSamlDetails(config.type);
        break;
      default:
        break;
    }
  };

  // Close Modal
  const dailogModalDecline = () => {
    setModalState((prev) => ({ ...prev, dailogModal: false }));
    close();
  };

  return (
    open &&
    modalState?.dailogModal && (
      <>
        <DailogNew
          isOpen={modalState.dailogModal}
          accept={dailogModalAccept}
          decline={dailogModalDecline}
          header={modalState.dailogModalHeader}
          content={modalState.dailogModalContent}
          config={modalState.dailogModalConfig}
          modalStyleType={modalState.dailogModalStyleType}
          submitButtonLabel={modalState.submitButtonLabel}
        />
        <VideoModal
          isOpen={videoModalStates.isVideoModalOpen}
          toggle={() => {
            setVideoModalStates((prev) => ({
              ...prev,
              isVideoModalOpen: false,
            }));
          }}
          videoUrl={videoModalStates.videoUrl}
          title={videoModalStates.title}
        />
      </>
    )
  );
};

export default SamlConfiguration;
