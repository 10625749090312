let MAGIC_LINK_CONSTANTS = {
  MAGIC_LINK: {
    TITLE: "magic_link",
    ACTIONS: {
      CREATE: "create",
      LIST: "list",
      EDIT: "update",
      SHOW: "show",
      DELETE: "destroy",
      EXPORT: "export",
    },
  },

  MAGIC_LINK_ROUTE: {
    LIST: {
      NAME: "Magic link Users",
      ROUTE: "/magic-link-users/list",
      HEADER_TITLE: "Magic Link Users",
      ADD_BUTTON_TITLE: "Add",
      EDIT_BUTTON_TITLE: "Edit",
      REMOVE_BUTTON_TITLE: "Remove",
      ACTIVE_BUTTON_TITLE: "Active",
      INACTIVE_BUTTON_TITLE: "In-Active",
      EDIT_USER: "Edit User",
      REMOVE_USER: "Remove User",
      STATUS: "Status",
      RESEND_EMAIL_VERIFICATION: "Resend email verification",
      ACTIVATE_USER_TOOLTIP: "Activate",
      INACTIVATE_USER_TOOLTIP: "De-Activate",
      EDIT_USER_TOOLTIP: "Edit",
      REMOVE_USER_TOOLTIP: "Remove",
      VALIDATE_USERS: "Validate Users",
      VALIDATE_USER_TOOL_TIP: "User Bounce Validation",
      RESEND_MAGIC_LINK: "Resend Magic Link",
      RESEND_MAGIC_LINK_TOOLTIP: "Resend Magic Link",
      DELETE_MAGIC_LINK_USER: "Delete Magic link User",
      DELETE_SUCCESS_MAGIC_LINK_USER: "Deleted Magic link user successfully",
      INACTIVATE_MAGIC_LINK_USER:
        "Are you sure want to de-activate the Magic Link User?",
      ACTIVATE_MAGIC_LINK_USER:
        "Are you sure want to activate the Magic Link User?",
    },
    CREATE: {
      NAME: "Add User",
      ROUTE: "/magic-link-users/add",
      HEADER_TITLE: "Add User",
    },
    EDIT: {
      NAME: "Edit User",
      ROUTE: "/magic-link-users/:id/edit",
      HEADER_TITLE: "Edit User",
    },
  },

  ACTIVATE: {
    SUCCESS: "Magic Link User activated successfully",
  },
  IN_ACTIVATE: {
    SUCCESS: "Magic Link User inactivated successfully",
  },
  MAGIC_LINK_USER_COLOR: ["#606B77", "#046CD9", "#FF5A5A"],
  PAGINATION: {
    DEFAULT_SORT_ORDER: "desc",
    DEFAULT_SORT_COLUMN: "created_at",
  },
};
export default MAGIC_LINK_CONSTANTS;
