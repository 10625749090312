import { Axios } from "../lib/axios";

export const UserServices = {
  getUserLogin,
  getUserLogout,
  verifyEmail,
  resetPassword,
  forgotPassword,
  changePassword,
  codeVerification,
  updateDisplayName,
  getTokenFromRefreshToken,
  getUsers,
  getUserDetails,
  getUserLoginWithSSO,
  sendverificationmailForPasswordSetting,
  userSingleSignOn,
};

function getUsers(url) {
  return Axios.get(url);
}

function getUserDetails(userId) {
  return Axios.get(`user/${userId}`);
}

function getUserLogin(body) {
  return Axios.post("auth/login", body);
}

function getUserLoginWithSSO(body) {
  return Axios.post("loginWithSSO", body);
}

function getUserLogout() {
  return Axios.get("auth/logout");
}

function verifyEmail(code) {
  return Axios.get(`user/${code}/verify`);
}

function resetPassword(body) {
  return Axios.post(`auth/setpassword`, body);
}

function forgotPassword(body) {
  return Axios.post(`auth/sendverificationmailForPasswordSetting`, {
    email: body,
  });
}

function changePassword(body) {
  return Axios.post("auth/changePassword", body);
}

function codeVerification(code) {
  return Axios.get(`/verifyCodeExpiry/${code}`);
}

function getTokenFromRefreshToken() {
  return Axios.post(
    "auth/token",
    { refresh_token: localStorage.getItem("refresh_token") },
    {}
  );
}

function updateDisplayName(body) {
  return Axios.put(`displayName`, body);
}

function sendverificationmailForPasswordSetting(body) {
  return Axios.post(`auth/sendverificationmailForPasswordSetting`, body);
}

function userSingleSignOn(input) {
  return Axios.post(`auth/userSingleSignOn`, input);
}
