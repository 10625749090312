let SAML_CONSTANTS = {
  CONFIGURATION_SAML: {
    DAILOG: {
      ENABLE_HEADER_TITLE: "Enabling SSO SAML (3rd party authentication)",
      VIEW_HEADER_TITLE: "Details of SSO SAML (3rd party authentication)",
    },
    MODE: {
      SETUP_SAML_DETAILS: "setup_saml",
      EDIT_SAML_DETAILS: "edit_saml",
    },
    ADD: "Added SAML Details Successfully",
    UPDATE: "Updated SAML Details Successfully",
  },
  ACL: {
    SAML_CONFIGURATION: {
      TITLE: "saml_configuration",
      ACTIONS: {
        SHOW: "show",
        CREATE: "create",
        EDIT: "update",
      },
    },
  },
};
export default SAML_CONSTANTS;
