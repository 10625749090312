import React from "react";
import Collapsible from "react-collapsible";
import TimeSince from "../Utils/TimeSince";
import { formatRoute } from "react-router-named-routes";
import constants from "../Utils/constants";
import { useHistory } from "react-router-dom";
import ReactTooltip from "react-tooltip";
import CustomEllipsis from "../Utils/CustomEllipsis";
import CustomReactTooltip from "./CustomReactTooltip";

const BoardDashboard = ({
  boardType,
  isOpen,
  type,
  data,
  title,
  callApiWithType,
}) => {
  let history = useHistory();

  // detail page handler, go to detail page
  const detailsPageHandler = (board) => {
    let path =
      boardType === "board"
        ? constants.APPLICATION_ROUTE.MY_BOARDS.DASHBOARD.ROUTE
        : constants.APPLICATION_ROUTE.VISUALIZATION.PREVIEW_WITH_ID.ROUTE;
    history.push({
      pathname: formatRoute(path, { id: board.id }),
    });
  };
  return (
    <div style={{ width: "100%" }}>
      <div key="1" className="recent-viewed-container">
        <Collapsible
          open={isOpen}
          trigger={
            <div
              onClick={() => {
                callApiWithType(type);
              }}
              className="d-flex justify-content-between"
            >
              <h4 className="recent-view-header">{title}</h4>
              <div className="recent-view-collapse-icon">
                <span
                  className="material-icons-round icon-fs-22"
                  style={{ color: "#5B87C4", fontWeight: "400" }}
                >
                  {isOpen ? "expand_less" : "expand_more"}
                </span>
              </div>
            </div>
          }
        >
          {data && data.length > 0 ? (
            <div>
              {data?.map((b, i) => (
                <div key={i}>
                  <div
                    className="dashboard-board-card"
                    onClick={() => detailsPageHandler(b)}
                  >
                    <h1 data-tip data-for={b.name} className="heading">
                      {" "}
                      {b.name.length > 19
                        ? b.name.slice(0, 19) + "..."
                        : b.name}
                    </h1>
                    <ReactTooltip id={b.name} place="top" effect="solid">
                      {b.name}
                    </ReactTooltip>
                    <div className="report-para">
                      Last Updated:{" "}
                      <span
                        style={{ fontWeight: "400" }}
                        className="text-black2"
                      >
                        {boardType === "board"
                          ? TimeSince(
                              b.updated_at && b.updated_at !== null
                                ? b.updated_at
                                : b.created_at
                            )
                          : TimeSince(
                              b.updated_at && b.updated_at !== null
                                ? b.updated_at
                                : b.created_by_user.updated_at
                            )}
                      </span>
                    </div>
                    <div className="report-para">
                      By:{" "}
                      <span
                        style={{ fontWeight: "400" }}
                        className="text-black2"
                        data-for={`boardName-${i}`}
                        data-tip={
                          boardType === "board"
                            ? b.owner.display_name || b.owner.first_name
                            : b.updated_by_user
                            ? b.updated_by_user.display_name ||
                              b.updated_by_user.first_name
                            : b.created_by_user
                            ? b.created_by_user.display_name ||
                              b.created_by_user.first_name
                            : "------"
                        }
                      >
                        {(() => {
                          const displayText =
                            boardType === "board"
                              ? b.owner.display_name || b.owner.first_name
                              : b.updated_by_user
                              ? b.updated_by_user.display_name ||
                                b.updated_by_user.first_name
                              : b.created_by_user
                              ? b.created_by_user.display_name ||
                                b.created_by_user.first_name
                              : "------";
                          return CustomEllipsis(displayText).text;
                        })()}
                      </span>
                      <CustomReactTooltip
                        id={`boardName-${i}`}
                        place="top"
                        className="custom-react-tooltip-board"
                      />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          ) : (
            <div className="no-board">
              {boardType === "board"
                ? "No Board Available"
                : "No Visualization Available"}
            </div>
          )}
        </Collapsible>
      </div>
    </div>
  );
};

export default BoardDashboard;
