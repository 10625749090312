import moment from "moment";

export const isMagicLinkUserExpired = (verificationSentOn, expirationTime) => {
  if (!verificationSentOn || !expirationTime) return false;

  // Convert verificationSentOn to moment object
  const verificationMoment = moment(verificationSentOn);

  // Add expiration time
  const expirationMoment = verificationMoment.add(expirationTime, "hours");

  // Compare with the current time
  return moment().isAfter(expirationMoment);
};
