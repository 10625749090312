import React from "react";
import { Button, Modal, ModalBody, ModalFooter, ModalHeader } from "reactstrap";

const DailogNew = (props) => {
  const {
    isOpen,
    cancel = true,
    submit = true,
    submitButtonLabel = "Confirm",
  } = props;
  return (
    <Modal
      isOpen={isOpen}
      toggle={props.toggle || props.decline}
      className={`${props.modalStyleType} modal-confirm modal-sm custom-modal`}
    >
      <ModalHeader toggle={props.decline}>{props.header}</ModalHeader>
      <ModalBody>{props.content}</ModalBody>
      {props.config && props.config.type && (
        <ModalFooter>
          {cancel && (
            <Button
              className={"btn-outline-primary modal-btn"}
              onClick={props.decline}
            >
              Cancel
            </Button>
          )}{" "}
          {submit && (
            <Button
              className={"modal-right-btn modal-btn"}
              onClick={() => {
                props.accept(props.config);
              }}
            >
              {submitButtonLabel}
            </Button>
          )}
        </ModalFooter>
      )}
    </Modal>
  );
};

export default DailogNew;
