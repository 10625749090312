import React from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import moment from "moment";
import CustomReactTooltip from "./CustomReactTooltip";
import EllipsisForModel from "../__test__/EllipsisForModel";

const PublishCalendarModal = ({
  isOpen,
  toggle,
  onClose,
  modalHeaderClassName,
  borderLeftStyle,
  colorIconStyle,
  eventTitle,
  displaySegment,
  displayReportType,
  eventTopic,
  redirectToReport,
  reportButtonIcon,
  displayQuater,
  displayAuthorName,
  showFullDate,
  fileName,
}) => {
  // Getting today's date as a Date object.
  const todayDate = moment().startOf("day");
  // getting todaydate for comparing with fulldate.
  const showFullDateObj = moment(showFullDate).startOf("day");

  let truncatedEventTitle = EllipsisForModel(eventTitle, 35);
  // This change is made to hide the 'topic'
  // let truncatedEventTopic = EllipsisForModel(eventTopic, 60);
  let truncetedCompanyText = EllipsisForModel(fileName, 60);
  let truncatedReportFileText = EllipsisForModel(fileName, 128);
  let truncatedAuthorName = EllipsisForModel(displayAuthorName, 42);

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop={true}
      keyboard={false}
      style={{ width: "500px", height: "332px", ...borderLeftStyle }}
      className={"modal-lg modal-w-header custom-calendar-modal primary-modal"}
    >
      <ModalHeader>
        <div className={modalHeaderClassName}>
          <div className="d-flex">
            <span
              className="material-icons-outlined icon-fs-22"
              style={{ ...colorIconStyle }}
            >
              date_range
            </span>
            {/* <img src={publish_calendar_header_1} alt="publish_calendar_header_1" style={{colorIconStyle}} /> */}
            <h2 className="ml-2">Published Event</h2>
          </div>
          <span
            onClick={onClose}
            className="material-icons close-button"
            style={{ cursor: "pointer" }}
          >
            close
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="container">
          <div className="row">
            <div data-for="eventTitle" data-tip={eventTitle || "N/A"}>
              {eventTitle.length > 35 ? (
                <CustomReactTooltip id="eventTitle" />
              ) : null}
              <h2 className="published-event-segment-text">
                {truncatedEventTitle.text}
              </h2>
            </div>
          </div>
          <div className="row">
            {displayReportType === "Company Research" && (
              <div className="published-event-segment-div">
                <p>{displaySegment}</p>
              </div>
            )}
            {/* 
            // This change is made to hide the 'topic'
            <div data-for="eventTopic" data-tip={eventTopic || "N/A"}>
              {eventTopic.length > 60 ? (
                <CustomReactTooltip id="eventTopic" />
              ) : null}
              <span className="ml-1">{truncatedEventTopic.text}</span>
            </div> */}
          </div>
          <div className="row mt-2 justify-content-between">
            <span className="show-event-modal-text">{showFullDate}</span>
            {showFullDateObj < todayDate && (
              <button
                className="btn btn-primary go-to-report-btn"
                onClick={redirectToReport}
              >
                <span>Go to report</span>
                <img src={reportButtonIcon} alt="reportButtonIcon" />
              </button>
            )}
          </div>
        </div>

        <div className="container mt-3">
          <div className="row inner-publish-modal-div-bg">
            <div className="col-12">
              {displayReportType === "Company Research" ? (
                <>
                  <div className="row">
                    <div className="col">
                      <span>Company:</span>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      data-for="selectedCompany"
                      data-tip={fileName || "N/A"}
                    >
                      {fileName?.length > 60 ? (
                        <CustomReactTooltip id="selectedCompany" />
                      ) : null}
                      <h2 className="col published-event-bold-text">
                        {truncetedCompanyText.text}
                      </h2>
                    </div>
                  </div>
                </>
              ) : (
                <>
                  <div className="row">
                    <div className="col">
                      <span>Report:</span>
                    </div>
                  </div>
                  <div className="row">
                    <div
                      data-for="selectedReportFile"
                      data-tip={fileName || "N/A"}
                    >
                      {fileName?.length > 128 ? (
                        <CustomReactTooltip id="selectedReportFile" />
                      ) : null}
                      <h2
                        className="col published-event-bold-text"
                        style={{ paddingRight: "14px" }}
                      >
                        {truncatedReportFileText.text}
                      </h2>
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
          <div className="row inner-publish-modal-div-bg mt-2">
            <div className="col-6">
              <div className="row">
                <div className="col">
                  <span>Quarter and Year:</span>
                </div>
              </div>
              <div className="row">
                <div className="col published-event-bold-text">
                  <span>{displayQuater}</span>
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col">
                  <span>Author:</span>
                </div>
              </div>
              <div className="row">
                <div className="col published-event-bold-text">
                  <div
                    data-for="displayAuthorName"
                    data-tip={displayAuthorName || "N/A"}
                  >
                    {displayAuthorName?.length > 42 ? (
                      <CustomReactTooltip id="displayAuthorName" />
                    ) : null}
                    <span>{truncatedAuthorName.text}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PublishCalendarModal;
