import React, { useEffect, useState } from "react";

import { useHistory } from "react-router";
import { formatRoute } from "react-router-named-routes";

import { connect } from "react-redux";
import { clientRoles } from "../../Store/Actions/Client";
import { capitalize, isEmpty } from "lodash";
import MAGIC_LINK_CONSTANTS from "./utils/constants";
import { ClientServices } from "../../Services/Client";
import constants from "../../Utils/constants";
import { MagicLinkUserServices } from "./Services/MagicLinkUserServices";
import CustomDropdownList from "../../Components/CustomDropdownList";
import DocumentTitle from "../../Utils/DocumentTitle";
import Toast from "../../Utils/Toast";
import Notification from "../../Components/Notification";
import DailogNew from "../../Components/DailogNew";
import { normalizeToHours } from "../../Helpers/Helper";
import CustomReactTooltip from "../../Components/CustomReactTooltip";
import CLIENT_USER_MANAGEMENT from "../UserManagement/Utils/constants";

const ManageMagicUser = (props) => {
  const history = useHistory();

  const [isModalOpen, setIsModalOpen] = useState(null);
  const [modalContent, setModalContent] = useState("");
  const [modalHeader, setModalHeader] = useState("");
  const [modalConfig, setModalConfig] = useState({});
  const [modalStyle, setModalStyle] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [apiError, setApiError] = useState(null);
  const [isFormSubmitted, setisFormSubmitted] = useState(false);
  const [isExpirationTimeUnlimited, setIsExpirationTimeUnlimited] =
    useState(false);

  const [time, setTime] = useState("In Hours");

  const [userDetails, setUserDetails] = useState({
    userRoleId: "",
    firstName: "",
    lastName: "",
    email: "",
    contactNo: "",
    expirationTime: "",
  });

  useEffect(() => {
    initializeData();
  }, []);

  const initializeData = () => {
    let userId = props.match.params.id || null;

    updateDocumentTitle(userId);
    fetchClientUserRoles();

    if (userId !== null) prepopulateData(userId);
  };

  const updateDocumentTitle = (userId) => {
    DocumentTitle(
      userId === null
        ? MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.CREATE.HEADER_TITLE
        : MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.EDIT.HEADER_TITLE
    );
  };

  const fetchClientUserRoles = () => {
    ClientServices.getClientUserRoles()
      .then((res) => {
        let response =
          res?.data?.map((role) => ({ ...role, is_selected: false })) || [];
        props.setClientRoles(response);
      })
      .catch(handleApiError);
  };

  const handleApiError = (error) => {
    Toast(error?.data?.error || constants.ERROR.SOMETHING_WENT_WRONG, "error");
  };

  const prepopulateData = async (userId) => {
    try {
      const res = await MagicLinkUserServices.getSpecificMagikLinkUser(userId);
      const response = res?.data?.data || {};
      let expiration_time = response?.expiration_time >= 24;
      let tempExpTime = response?.expiration_time;
      if (expiration_time) {
        setTime("In Days");
        tempExpTime = tempExpTime / 24;
      }

      setUserDetails((prevDetails) => ({
        ...prevDetails,
        userRoleId: response?.role?.id || "",
        firstName: response?.first_name || "",
        lastName: response?.last_name || "",
        email: response?.email || "",
        contactNo: response?.mobile || "",
        expirationTime: tempExpTime,
      }));

      setIsExpirationTimeUnlimited(response?.expiration_time === null);

      setApiError(null);
      setisFormSubmitted(false);

      setRole(response.userRoleId);
    } catch (error) {
      handleApiError(error);
    }
  };

  useEffect(() => {
    if (isFormSubmitted) {
      isFormValid();
    }
    // setRole();
  }, [userDetails]);

  const changeRoute = () => {
    history.push({
      pathname: constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE,
      state: {
        selectedTab:
          CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.MAGIC_LINK_USER_TAB,
      }, // Passing tab as state
    });
  };

  const dailogModalAccept = (config) => {
    setIsModalOpen(false);
    setModalHeader("");
    setModalContent("");
    setModalConfig({});
  };
  const dailogModalDecline = () => {
    setIsModalOpen(false);
    setModalHeader("");
    setModalContent("");
    setModalConfig({});
  };
  const handleSubmit = () => {
    setisFormSubmitted(true);

    if (!isFormValid()) {
      let userId = props.match.params.id ? props.match.params.id : null;
      let prepareObject = {
        first_name: capitalize(userDetails.firstName),
        last_name: capitalize(userDetails.lastName),
        role_id: userDetails.userRoleId,
        email: userDetails.email,
        mobile: userDetails.contactNo,
        expiration_time: isExpirationTimeUnlimited
          ? null
          : normalizeToHours(userDetails.expirationTime, time),
      };

      if (userId === null) {
        //create magic link user
        MagicLinkUserServices.addMagicLinkUser(prepareObject)
          .then((res) => {
            saveClientUserSuccess();
          })
          .catch((error) => {
            setApiError(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG
            );
          });
      } else {
        MagicLinkUserServices.editMagicLinkUser(userId, prepareObject)
          .then((res) => {
            saveClientUserSuccess();
          })
          .catch((error) => {
            setApiError(
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG
            );
          });
      }
    }
  };
  const saveClientUserSuccess = () => {
    let userId = props.match.params.id ? props.match.params.id : null;
    let dailogModalHeader = (
      <>
        <h3 className="text-darkblue2 font-weight-bold">Success</h3>
      </>
    );
    let dailogModalContent = (
      <>
        <div className="activate-body">
          <h2 className="font-weight-semibold mb-0">
            {userId === null
              ? constants.CLIENT_USER.CREATE.SUCCESS
              : constants.CLIENT_USER.EDIT.SUCCESS}
          </h2>
        </div>
      </>
    );
    setIsModalOpen(true);
    setModalHeader(dailogModalHeader);
    setModalContent(dailogModalContent);
    setModalConfig({ type: false, id: null });
    setModalStyle("success-modal");
    setTimeout(
      () =>
        props.history.push({
          pathname: formatRoute(
            constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE
          ),
          state: {
            selectedTab:
              CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.MAGIC_LINK_USER_TAB,
          }, // Passing tab as state
        }),
      1500
    );
  };
  const isFormValid = () => {
    const errors = {
      userRoleIdError:
        userDetails.userRoleId === "" || userDetails.userRoleId === null,
      userFirstNameError: isEmpty(userDetails?.firstName?.trim()),
      userLastNameError: isEmpty(userDetails?.lastName?.trim()),
      userEmailError:
        isEmpty(userDetails?.email?.trim()) ||
        !constants.REG_EX.EMAIL_REG_EX.test(
          String(userDetails?.email).toLowerCase()
        ),
      userContactNumberError:
        userDetails?.contactNo &&
        !constants.REG_EX.PHONE_REG_EX.test(userDetails.contactNo.trim()),
      userExpirationTime:
        !isExpirationTimeUnlimited && !userDetails?.expirationTime,
    };

    const formError = Object.values(errors).some(Boolean);
    setFormErrors(errors);
    setApiError(null);

    return formError;
  };
  const resetForm = () => {
    let userId = props.match.params.id ? props.match.params.id : null;

    setFormErrors({
      userRoleIdError: false,
      userFirstNameError: false,
      userLastNameError: false,
      userEmailError: false,
      userContactNumberError: false,
      userExpirationTime: false,
    });
    setApiError(null);
    if (userId !== null) {
      prepopulateData(userId);
    } else {
      setUserDetails({
        userRoleId: "",
        firstName: "",
        lastName: "",
        email: " ",
        contactNo: "",
        expirationTime: "",
      });
      setisFormSubmitted(false);
      setApiError(null);
    }
  };
  // Handle checkbox change
  const handleCheckboxChange = (e) => {
    const isChecked = e.target.checked;
    setIsExpirationTimeUnlimited(isChecked);

    if (isChecked) {
      setUserDetails((prev) => ({
        ...prev,
        expirationTime: "", // Clear input when checkbox is checked
      }));
    }
  };

  // Handle input change
  const handleExpirationTimeChange = (e) => {
    const { value } = e.target;
    setUserDetails((prev) => ({
      ...prev,
      expirationTime: parseInt(value),
    }));

    if (value) {
      setIsExpirationTimeUnlimited(false); // Uncheck checkbox when user types
    }
  };
  const handleInputChange = (e) => {
    const { id, value } = e.target;

    setUserDetails((prevDetails) => ({
      ...prevDetails, // Spread previous state
      [id]: value, // Update only the changed field
    }));
  };

  // handle role change
  const handleRoleChange = (e) => {
    setUserDetails((prev) => ({
      ...prev,
      userRoleId: e.id,
    }));
  };
  const setRole = () => {
    let clientRoles = [...props.client.clientRoles];

    clientRoles.map((c) => {
      return {
        ...c,
        is_selected: c.id === userDetails.userRoleId,
      };
    });

    props.setClientRoles(clientRoles);
  };

  const userId = props.match.params.id ? props.match.params.id : null;

  let roles =
    props.client && props.client.clientRoles ? props.client.clientRoles : [];
  const updatedRoles = roles
    .filter((role) => role.short_name !== "client_super_admin") // Remove client_super_admin
    .map((role) => ({
      ...role,
      name: role.short_name === "client_admin" ? "Admin" : role.name,
      is_selected: role.id === userDetails.userRoleId,
    }))
    .sort((a, b) => b.id - a.id);

  return (
    <aside className="main_content">
      <div
        className="m-3 mb-0 bg-white px-3 br_8 main_content_height"
        style={{ overflowY: "auto" }}
      >
        <div className="manage-client">
          <div className="">
            <div className={"add-user-card"}>
              <form className={"form-wrapper"}>
                {apiError !== null && (
                  <Notification color={"danger"} message={apiError} />
                )}
                <div className="card mt-3">
                  <div className="card-header title-cancel-header d-flex align-items-center">
                    <h3 className="text-darkblue2 mb-0 font-weight-bold">
                      {userId === null
                        ? MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.CREATE
                            .HEADER_TITLE
                        : MAGIC_LINK_CONSTANTS.MAGIC_LINK_ROUTE.EDIT
                            .HEADER_TITLE}
                    </h3>
                    <span
                      className="material-icons-round text-darkblue2 ml-auto cursor-pointer"
                      onClick={changeRoute}
                    >
                      close
                    </span>
                  </div>
                  <div className="card-body">
                    <div className="card-body-header">
                      <h5 className="mb-0 font-weight-semibold">
                        User Information
                      </h5>
                    </div>
                    <div className="form-field-wrapper">
                      <div className="row">
                        <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                          <div className={"row"}>
                            <div className="col-sm-6 form-group position-relative">
                              <label htmlFor="firstName">
                                First Name{" "}
                                <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control capitalize ${
                                  formErrors.userFirstNameError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userFirstNameError === false
                                    ? ""
                                    : ""
                                }`}
                                id="firstName"
                                placeholder="First Name"
                                value={userDetails.firstName}
                                onChange={handleInputChange}
                              />
                              {formErrors.userFirstNameError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label htmlFor="lastName">
                                Last Name <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control capitalize ${
                                  formErrors.userLastNameError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userLastNameError === false
                                    ? ""
                                    : ""
                                }`}
                                id="lastName"
                                placeholder="Last Name"
                                value={userDetails.lastName}
                                onChange={handleInputChange}
                              />
                              {formErrors.userLastNameError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-lg-6 col-sm-6 form-group position-relative">
                              <label for="segments">
                                Role <span className={"mandatory"}>*</span>
                                <span
                                  data-tip=""
                                  data-for="roles-info"
                                  style={{
                                    color: "#1E81E8",
                                    verticalAlign: "middle",
                                  }}
                                  className="material-icons-round icon-fs-14 ml-1 cursor-pointer"
                                >
                                  info
                                </span>
                                <CustomReactTooltip
                                  id="roles-info"
                                  place="right"
                                >
                                  <dl className="mb-0">
                                    <dt>Admin:</dt>
                                    <dd>Full access with user management.</dd>
                                    <dt className="mb-1">Creator:</dt>
                                    <dd>
                                      Create and manage content but cannot
                                      manage users.
                                    </dd>
                                    <dt className="mb-1">Viewer:</dt>
                                    <dd>Restricted access to view only.</dd>
                                  </dl>
                                </CustomReactTooltip>
                              </label>
                              <div
                                className={`${
                                  formErrors.userRoleIdError ? "is-invalid" : ""
                                } custom-combobox-list`}
                              >
                                <CustomDropdownList
                                  data={updatedRoles}
                                  handleChange={handleRoleChange}
                                  placeholder={"Select Role"}
                                />
                              </div>
                              {formErrors.userRoleIdError && (
                                <div className="invalid-tooltip display-block d-block">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label for="email">
                                Email <span className={"mandatory"}>*</span>
                              </label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.userEmailError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userEmailError === false ? "" : ""
                                }`}
                                id="email"
                                placeholder="Email (example@domain.com)"
                                value={userDetails.email}
                                onChange={handleInputChange}
                              />
                              {formErrors.userEmailError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.INVALID_EMAIL}
                                </div>
                              )}
                            </div>
                            <div className="col-sm-6 form-group position-relative">
                              <label for="contactNo">Contact No.</label>
                              <input
                                type="text"
                                className={`form-control ${
                                  formErrors.userContactNumberError === true
                                    ? "is-invalid"
                                    : ""
                                } ${
                                  formErrors.userContactNumberError === false
                                    ? ""
                                    : ""
                                }`}
                                id="contactNo"
                                placeholder="Contact No. (99999999)"
                                value={userDetails.contactNo}
                                onChange={handleInputChange}
                              />
                              {formErrors.userContactNumberError && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.INVALID_CONTACT_NUMBER}
                                </div>
                              )}
                            </div>

                            <div className="col-sm-6 form-group position-relative">
                              <label htmlFor="expirationTime">
                                Expiration Time
                                <span className={"mandatory"}>*</span>
                              </label>
                              <div
                                className={
                                  "d-flex flex-column flex-sm-row align-items-start"
                                }
                              >
                                <div className="d-flex align-items-center w-sm-auto mt-2">
                                  <input
                                    className="form-check-input custom-checkbox-new ml-0 mt-0"
                                    type="checkbox"
                                    id="expirationTime"
                                    checked={isExpirationTimeUnlimited}
                                    onChange={handleCheckboxChange}
                                    disabled={userDetails.expirationTime}
                                  />
                                  <label
                                    className="form-check-label ml-1"
                                    htmlFor="isExpirationTimeUnlimited"
                                    style={{ fontSize: "12px" }}
                                  >
                                    Unlimited
                                  </label>
                                </div>
                                <div
                                  style={{
                                    padding: "4px",
                                    minWidth: "140px",
                                  }}
                                >
                                  <div
                                    className={"input-group"}
                                    id="expirationTime"
                                    style={{ alignItems: "baseline" }}
                                  >
                                    <input
                                      type="number"
                                      className={`form-control ${
                                        formErrors.userExpirationTime === true
                                          ? "is-invalid"
                                          : ""
                                      }`}
                                      id="expirationTime"
                                      style={{
                                        maxHeight: "30px",
                                        maxWidth: "90px",
                                      }}
                                      value={userDetails.expirationTime}
                                      onChange={handleExpirationTimeChange}
                                      disabled={isExpirationTimeUnlimited}
                                      min={1}
                                    />
                                    <span
                                      className="ml-3"
                                      style={{
                                        color:
                                          !isExpirationTimeUnlimited &&
                                          time === "In Days"
                                            ? "#1E81E8"
                                            : "#606B77",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      In Days
                                    </span>
                                    <label className="exp-switch ml-2">
                                      <input
                                        type="checkbox"
                                        checked={time === "In Hours"}
                                        disabled={isExpirationTimeUnlimited}
                                        onChange={(e) => {
                                          if (e.target.checked) {
                                            setTime("In Hours");
                                          } else {
                                            setTime("In Days");
                                          }
                                        }}
                                      />
                                      <span className="exp-slider" />
                                    </label>
                                    <span
                                      style={{
                                        color:
                                          !isExpirationTimeUnlimited &&
                                          time === "In Hours"
                                            ? "#1E81E8"
                                            : "#606B77",
                                        fontSize: "12px",
                                        fontWeight: "400",
                                      }}
                                    >
                                      In Hours
                                    </span>
                                  </div>
                                </div>
                              </div>
                              {formErrors.userExpirationTime && (
                                <div className="invalid-tooltip">
                                  {constants.FORM.MANDATORY_FIELD}
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="form-submit-buttons">
                      <div className={"row"}>
                        <div className="col-xl-8 offset-xl-2 col-md-10 offset-md-1">
                          <div className="text-right form-buttons">
                            <button
                              type="button"
                              className="btn btn-outline-primary modal-btn"
                              onClick={resetForm}
                            >
                              Reset
                            </button>
                            <button
                              type="button"
                              className="btn btn-primary modal-btn"
                              onClick={handleSubmit}
                            >
                              {props.match.params.id ? `Update` : `Save`}
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      <DailogNew
        isOpen={isModalOpen}
        accept={dailogModalAccept}
        decline={dailogModalDecline}
        header={modalHeader}
        content={modalContent}
        config={modalConfig}
        modalStyleType={modalStyle}
      />
    </aside>
  );
};

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    setClientRoles: (allClientRoles) => {
      dispatch(clientRoles(allClientRoles));
    },
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ManageMagicUser);
