import React, { Component } from "react";
import { formatRoute } from "react-router-named-routes";
import {
  ButtonToggle,
  Container,
  Form,
  FormGroup,
  Input,
  Label,
  Row,
} from "reactstrap";
import Notification from "../Components/Notification";
import logo from "../Images/INSIGHTCENTER@2x.png";
import login_building from "../Images/login_building.png";
import login_map from "../Images/login_map.png";
import TBRLogo from "../Images/TBRLogo";
import { UserServices } from "../Services/User";
import constants from "../Utils/constants";
import DocumentTitle from "../Utils/DocumentTitle";

class ForgotPassword extends Component {
  constructor(props) {
    super(props);
  }
  state = {
    email: "",
    apiErrors: "",
    emailError: false,
    isSubmitted: false,
    successMessage: "",
    buttonInActive: true,
  };

  handleBackButtonClick = (e) => {
    e.preventDefault();
    this.props.history.push(
      formatRoute(constants.APPLICATION_ROUTE.LOGIN.ROUTE, {})
    );
  };

  isFormValid = () => {
    var re = new RegExp(constants.REG_EX.EMAIL_REG_EX);
    let emailError = !re.test(this.state.email);
    this.setState({
      emailError,
      isSubmitted: true,
      successMessage: "",
      apiErrors: "",
    });
    if (emailError === false) {
      this.setState({
        buttonInActive: false,
      });
      return true;
    }
    return false;
  };

  handleSubmit = (e) => {
    e.preventDefault();
    if (this.isFormValid()) {
      UserServices.forgotPassword(this.state.email)
        .then((data) => {
          // localStorage.setItem("email", this.state.email);
          this.setState({
            successMessage: data.data.message,
            apiErrors: "",
          });
          // this.props.history.push("/password");
        })

        .catch((error) => {
          this.setState({
            successMessage: "",
            apiErrors:
              error && error.data && error.data.error
                ? error.data.error
                : constants.ERROR.SOMETHING_WENT_WRONG,
          });
        })
        .finally(() => {
          this.setState({
            buttonInActive: true,
          });
        });
    }
  };

  handleInputChange = (e) => {
    this.setState(
      {
        [e.target.name]: e.target.value,
        buttonInActive: false,
      },
      () => {
        if (this.state.isSubmitted) this.isFormValid();
      }
    );
  };

  componentDidMount() {
    DocumentTitle(constants.RESET_PASSWORD.HEADER_TITLE);
  }

  render() {
    return (
      <div className="wrapper login_page">
        <main>
          <Container fluid>
            <Row>
              <aside className="col-md-6 px-0 hide-in-mobille">
                <div className="login_left">
                  <figure className="login_banner">
                    <img
                      src={login_building}
                      className="img-fluid"
                      alt="login banner"
                      title="login banner"
                    />
                  </figure>
                  <a href="/">
                    <img
                      style={{ width: "120px", marginLeft: "20px" }}
                      src="https://tbr-ggk.s3.us-east-2.amazonaws.com/production/TBR_2color_tagline.svg"
                      alt="TBR logo"
                    />
                    {/* <TBRLogo />
                    <span>{constants.PAGE.LOGIN.LOGO_TEXT}</span> */}
                  </a>
                </div>
              </aside>
              <aside className="col-md-6 px-0">
                <div className="login_right">
                  <figure>
                    <img
                      src={login_map}
                      className="img-fluid"
                      alt="map Banner"
                    />
                  </figure>
                  <Form
                    onSubmit={this.handleSubmit}
                    className={`form-wrapper reset-email`}
                  >
                    <span className="login_formlogos">
                      <img src={logo} className="img-fluid" alt="logo" />
                    </span>
                    {this.state.apiErrors.length > 0 && (
                      <Notification
                        color={"danger"}
                        message={this.state.apiErrors}
                      />
                    )}
                    {this.state.successMessage.length > 0 && (
                      <Notification
                        color={"success"}
                        message={this.state.successMessage}
                      />
                    )}
                    <FormGroup>
                      <h1 className="reset_password_header">
                        {constants.RESET_PASSWORD.HEADER_TITLE}
                      </h1>
                    </FormGroup>
                    <FormGroup>
                      <Label htmlFor="loginEmail">Email</Label>
                      <Input
                        className={`${
                          this.state.emailError ? "is-invalid" : ""
                        }`}
                        type="email"
                        placeholder="Email"
                        id="loginEmail"
                        name="email"
                        onChange={this.handleInputChange}
                      />
                      {this.state.emailError && (
                        <div className="invalid-tooltip">
                          Email must be valid
                        </div>
                      )}
                    </FormGroup>
                    <FormGroup className="d-flex justify-content-center">
                      <ButtonToggle
                        disabled={this.state.buttonInActive}
                        color="primary"
                        type="submit"
                        style={{ maxWidth: "0px", minWidth: "200px" }}
                      >
                        Request password Reset
                      </ButtonToggle>
                      <ButtonToggle
                        color="primary"
                        type="button"
                        style={{
                          minWidth: "100px",
                          marginLeft: "27px",
                        }}
                        onClick={(event) => this.handleBackButtonClick(event)}
                        className="d-inline-flex align-items-center justify-content-center"
                      >
                        <span class="material-icons-round icon-fs-14 mr-1">
                          west
                        </span>
                        Login
                      </ButtonToggle>
                    </FormGroup>
                  </Form>
                </div>
              </aside>
            </Row>
          </Container>
        </main>
      </div>
    );
  }
}
export default ForgotPassword;
