import React, { useEffect, useState } from "react";
import CopyInput from "../../Components/CopyInput";
import constants from "../../Utils/constants";

const SamlModalContent = ({
  handleSamlData,
  formErrors,
  ssosamlConfigValues,
}) => {
  const handleOnChangeSamlConfiguration = (e) => {
    const { id, value } = e.target;
    handleSamlData({ [id]: value });
  };

  return (
    <>
      <div className={"row first-row-identifier-reply"}>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label">
          <label for="entityId">Identifier (Entity ID)</label>
          <CopyInput
            value={ssosamlConfigValues.entityId}
            fontSize="12px"
            inputClass={`form-control`}
            inputId="entityId"
            readonly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label">
          <label for="replayUrl">
            Reply URL (Assertion Consumer Service URL)
          </label>
          <CopyInput
            value={ssosamlConfigValues.replayUrl}
            fontSize="12px"
            inputClass={`form-control`}
            inputId="replayUrl"
            readonly={true}
          />
        </div>
      </div>
      <div className={"row second-metadata"}>
        <div className="col-sm-12 form-group position-relative ssosaml-modal-label">
          <label for="endpointurl">
            Enter metadata document endpoint URL{" "}
            <span className="mandatory">*</span>
          </label>
          <input
            type="text"
            placeholder="https://"
            id={"metadataURL"}
            value={ssosamlConfigValues.metadataURL}
            className={`form-control fs-12 ${
              formErrors.metadataURLError === true ? "is-invalid" : ""
            }
            ${formErrors.metadataURLError === false ? "" : ""}`}
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.metadataURL && (
            <div className="saml-config-error">{formErrors.metadataURL}</div>
          )}
        </div>
      </div>
      <div className="row third-info-attribute">
        <div className="col-sm-12 form-group position-relative ssosaml-modal-label">
          <span style={{ fontWeight: "bold" }}>
            Map attributes between your SAML provider and your user pool
          </span>
          <span className="fs-12">
            Your required attribute are mapped to the equivalent SAML attribute.
            Each attribute you add must be mapped to a SAML attribute.
          </span>
        </div>
      </div>
      <div className="row fourth-pool-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <span className="mb-2">User pool attribute</span>
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <span className="mb-2">
            SAML Attribute <span className="mandatory">*</span>
          </span>
        </div>
      </div>
      <div className="row fifth-email-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control mb-2"}
            aria-describedby="button-addon2"
            id="email_attribute"
            placeholder="email"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.emailError === true ? "is-invalid" : ""
            }
            ${formErrors.emailError === false ? "" : ""}`}
            id={"email"}
            value={ssosamlConfigValues.email}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.email && (
            <div className="saml-config-error mb-2">{formErrors.email}</div>
          )}
        </div>
      </div>
      <div className="row six-givenname-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control mb-2"}
            aria-describedby="button-addon2"
            id="givenName_attribute"
            placeholder="first_name"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.givenNameError === true ? "is-invalid" : ""
            }
            ${formErrors.givenNameError === false ? "" : ""}`}
            id={"givenName"}
            value={ssosamlConfigValues.givenName}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.givenName && (
            <div className="saml-config-error mb-2">{formErrors.givenName}</div>
          )}
        </div>
      </div>
      <div className="row seven-name-attribute">
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={"form-control "}
            aria-describedby="button-addon2"
            id="name_attribute"
            placeholder="last_name"
            readOnly={true}
          />
        </div>
        <div className="col-sm-6 form-group position-relative ssosaml-modal-label mb-0">
          <input
            type="text"
            style={{ fontSize: "12px" }}
            className={`form-control ${
              formErrors.nameSSOSAMLError === true ? "is-invalid" : ""
            }
            ${formErrors.nameSSOSAMLError === false ? "" : ""}`}
            id={"name"}
            value={ssosamlConfigValues.name}
            placeholder="Enter SAML attribute name"
            onChange={(e) => {
              handleOnChangeSamlConfiguration(e);
            }}
          />
          {formErrors.name && (
            <div className="saml-config-error">{formErrors.name}</div>
          )}
        </div>
      </div>
    </>
  );
};

export default SamlModalContent;
