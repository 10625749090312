import { Axios } from "../lib/axios";

const samlRoute = "saml/";

const getSAMLUrls = () => {
  return Axios.get(`${samlRoute}getUrls`);
};
const addIDPSamlConfiguration = (body) => {
  return Axios.post(`${samlRoute}idp`, body);
};
function updateIDPSamlConfiguration(body) {
  return Axios.put(`${samlRoute}idp`, body);
}
function deleteIDPSamlConfiguration(clientId, body) {
  return Axios.patch(`${samlRoute}idp/${clientId}`, body);
}
const verifyMetaDataURL = (body) => {
  return Axios.post(`${samlRoute}verify`, body);
};
const fetchIDPSamlConfiguration = (clientId) => {
  return Axios.get(`${samlRoute}idp/${clientId}`);
};

export const SamlServices = {
  addIDPSamlConfiguration,
  updateIDPSamlConfiguration,
  deleteIDPSamlConfiguration,
  verifyMetaDataURL,
  getSAMLUrls,
  fetchIDPSamlConfiguration,
};
