import React, { useState } from "react";
import CustomReactTooltip from "../Components/CustomReactTooltip";
import Collapsible from "react-collapsible";
import { Draggable } from "react-beautiful-dnd";
import SubCategoryItem from "../Components/SubCategoryItem";
import Slugify from "../Utils/Slugify";
import StrictModeDroppable from "./StrictModeDroppable";

function MainCategoryItem(props) {
  const [isMainOpen, setIsMainOpen] = useState(true);
  const {
    provided,
    category,
    isUnpinAllowed,
    isCommentAddAllowed,
    isDisplayComments,
    isCommentViewAllowed,
    isCommentDeleteAllowed,
    isCommentStatusChangeAllowed,
    isCommentEditAllowed,
    hasAccess,
    isSubItemOpen,
    parentsProps,
    removeCategoryConfirmation,
    isMainItemOpen,
    showMoreHandler,
  } = props;
  return (
    <Collapsible
      open={isMainOpen && isMainItemOpen}
      onTriggerClosing={() => setIsMainOpen(false)}
      onTriggerOpening={() => setIsMainOpen(true)}
      contentInnerClassName={
        isSubItemOpen ? "test-aniket" : "test-aniket-drag-fix"
      }
      trigger={
        <div className="board-collapse-container trigger-icon">
          {hasAccess && (
            <span
              className="material-icons-outlined icon icon-fs-24 hover-button"
              style={{
                cursor: "grab",
                position: "absolute",
                margin: "12px -6px",
                color: "#E5E5E5",
              }}
              data-for="dragtooltip"
              data-tip="Drag to Move"
            >
              drag_indicator
              <CustomReactTooltip id="dragtooltip" multiline={true} />
            </span>
          )}
          <div
            id={`${Slugify(category.name)}_${category.id}`}
            className="head-container"
          >
            {category.name}
          </div>
          <div className="d-flex">
            {hasAccess && (
              <div
                onClick={() =>
                  removeCategoryConfirmation(
                    "cat",
                    category.board_id,
                    category.id
                  )
                }
                className="board-collapse-add-icon mar-right-18"
              >
                <span className="material-icons-outlined icon-fs-16 col-red">
                  delete
                </span>
              </div>
            )}
            <div className="board-collapse-add-icon mr-2">
              <span className="material-icons-round add icon-fs-16 col-gray">
                add
              </span>
              <span
                style={{
                  color: "#84909E",
                }}
                className="material-icons-round remove icon-fs-16"
              >
                remove
              </span>
            </div>
          </div>
        </div>
      }
    >
      <StrictModeDroppable
        droppableId={category?.id?.toString()}
        type="sub-category"
        className="droppable-container"
      >
        {(provided, snapshot) => (
          <div
            className="board-content-wrapper"
            isdraggingover={snapshot.isDraggingOver.toString()}
            ref={provided.innerRef}
            {...provided.droppableProps}
          >
            {category.subcategories?.map(
              (subcatgory, index) =>
                subcatgory.is_selected && (
                  <Draggable
                    draggableId={index + "_" + subcatgory?.id?.toString()}
                    index={index}
                    type="sub-category"
                    key={index + "_" + subcatgory.id}
                    isDragDisabled={!hasAccess}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        isdragging={snapshot.isDragging.toString()}
                        className={
                          isSubItemOpen
                            ? "tbr_position col-12 board-item"
                            : "tbr_position col-12 board-item Collapsible__drag_fix"
                        }
                        key={snapshot + "_" + subcatgory.id}
                      >
                        <SubCategoryItem
                          {...{
                            provided,
                            subcatgory,
                            isUnpinAllowed,
                            isCommentAddAllowed,
                            isDisplayComments,
                            isCommentViewAllowed,
                            isCommentDeleteAllowed,
                            isCommentStatusChangeAllowed,
                            isCommentEditAllowed,
                            parentsProps,
                            hasAccess,
                            isSubItemOpen,
                            showMoreHandler,
                          }}
                        />
                      </div>
                    )}
                  </Draggable>
                )
            )}

            {provided.placeholder}
          </div>
        )}
      </StrictModeDroppable>
    </Collapsible>
  );
}

export default MainCategoryItem;
