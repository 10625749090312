import React, { useState, useEffect, useRef } from "react";
import constants from "../Utils/constants";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import listPlugin from "@fullcalendar/list";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import { activeMenuTab } from "../Store/Actions/User";
import { connect } from "react-redux";
import { CalendarServices } from "../Services/Calendar";
import Toast from "../Utils/Toast";
import CalendarHeader from "../Components/CalendarHeader";
import CalendarSidebar from "../Components/CalendarSidebar";
import "react-datepicker/dist/react-datepicker.css";
import "rc-time-picker/assets/index.css";
import "../Styles/calendar.scss";
import go_to_report_btn from "../../src/Pages/SvgIcons/go_to_report_btn.svg";
import { useHistory } from "react-router-dom";
import PublishCalendarModal from "../Components/PublishCalendarModal";

const reportList = [
  { id: 1, label: "Company Research", value: "company_report" },
  { id: 2, label: "Benchmark", value: "benchmark" },
  { id: 3, label: "Special Report", value: "special_report" },
  { id: 4, label: "Market Forecast", value: "market_forecast" },
  { id: 5, label: "Market Landscape", value: "market_landscape" },
  { id: 6, label: "Customer Study", value: "customer_studies" },
  { id: 7, label: "Ecosystem", value: "ecosystem" },
];

const viewsForCalendar = {
  timeGridWeek: {
    type: "timeGrid",
    slotDuration: "24:00:00",
    allDaySlot: false,
    expandRows: true,
    scrollTime: "14:00:00",
    eventMaxStack: 10,
  },
  timeGridDay: {
    type: "dayGrid",
    slotDuration: "24:00:00",
    allDaySlot: false,
    expandRows: true,
    scrollTime: "14:00:00",
    eventMaxStack: 10,
  },
};

const WebinarCalendar = (props) => {
  let history = useHistory();

  // states for webianar calendar
  const [placeholderStartTime, setPlaceholderStartTime] = useState("");
  const [showEventModalIsOpen, setShowEventModalIsOpen] = useState(false);
  const [publishedEventModal, setPublishedEventModal] = useState(false);
  const [eventTitle, setEventTitle] = useState("");
  const [eventDescription, setEventDescription] = useState("");
  const [webinarLink, setWebinarLink] = useState("");
  const [hostName, setHostName] = useState("");
  const [hostDescription, setHostDescription] = useState("");
  const [allEvents, setAllEvents] = useState([]);
  const [displayEvents, setDisplayEvents] = useState([]);
  const [showFullDate, setShowFullDate] = useState("");
  const [searchText, setSearchText] = useState("");
  const [activeView, setActiveView] = React.useState("dayGridMonth");
  const [webinarDurationTime, setWebinarDurationTime] = useState("");
  const [calendarHeaderDate, setCalendarHeaderDate] = useState("");

  // states for published calendar
  const [displayEventTitle, setDisplayEventTitle] = useState("");
  // This change is made to hide the 'topic'
  // const [displayEventTopic, setDisplayEventTopic] = useState("");
  const [displayQuater, setDisplayQuater] = useState("");
  const [displayAuthorName, setDisplayAuthorName] = useState("");
  const [displayReportType, setDisplayReportType] = useState("");
  const [displaySegment, setDisplaySegment] = useState("");
  const [eventStatus, setEventStatus] = useState("");
  const calendarRef = useRef(null);
  const [fileName, setFileName] = useState("");

  //state for publish and webinar calendar object state
  const [publishCalendarObject, setPublishCalendarObject] = useState("");

  const [selectedUpcomingEvent, setSelectedUpcomingEvent] = useState(
    props?.location.state ? props?.location.state?.event : null
  );

  useEffect(() => {
    const { current: calendarDom } = calendarRef;
    const API = calendarDom ? calendarDom.getApi() : null;
    API && API.changeView(activeView);
    const prevButton = document.getElementById("calendar-prev-btn");
    const nextButton = document.getElementById("calendar-next-btn");
    const handlePrev = () => {
      API.prev();
      setCalendarHeaderDate(API.view.title);
    };
    const handleNext = () => {
      API.next();
      setCalendarHeaderDate(API.view.title);
    };
    prevButton.addEventListener("click", handlePrev);
    nextButton.addEventListener("click", handleNext);
    setCalendarHeaderDate(API.view.title);

    return () => {
      prevButton.removeEventListener("click", handlePrev);
      nextButton.removeEventListener("click", handleNext);
    };
  }, [activeView]);

  useEffect(() => {
    if (selectedUpcomingEvent) {
      upcomingEventRedirection(selectedUpcomingEvent);
    }
  }, [selectedUpcomingEvent]);

  useEffect(() => {
    getAllCalendarEvents();
    props.activeMenuTab(
      constants.APPLICATION_ROUTE.WEBINAR_CALENDAR.LIST.ROUTE
    );
  }, []);

  // fetching all the events data of the calendar
  const getAllCalendarEvents = () => {
    CalendarServices.getCalendarEvent("status=publish")
      .then(async (res) => {
        const data = res.data;
        const updatedWebinarEvents = await Promise.all(
          data.map((event) => {
            // updating the ID, bcoz published and webinar event sometime have same ID which make conflicting for filtering data
            event.id = "w" + event.id;
            event["title"] = event.name;
            event["start"] = event.start_date_time.split(".")[0];
            event["end"] = event.end_date_time.split(".")[0];
            if (event.status === "publish") {
              event["className"] = ["event", "publishedEvent"];
            }
            return event;
          })
        );
        getPublishedCalendarEvents("success", updatedWebinarEvents);
      })
      .catch((_error) => {
        getPublishedCalendarEvents("failed", []);
      });
  };

  const getPublishedCalendarEvents = (type, webinarData) => {
    CalendarServices.getPublishedCalendarEvent("status=publish")
      .then(async (response) => {
        const publishedData = response.data;
        let dateIndex = {};
        const updatedPublishedEvents = await Promise.all(
          publishedData.map((event) => {
            const dateTimeString = event?.published_date;
            event.id = "p" + event.id;
            event["name"] = event.title;
            event["title"] = `${event.calendar_quarter} ${event.title}`;

            const timezoneOffset = new Date().getTimezoneOffset();
            let eventStartTime = new Date(dateTimeString);

            eventStartTime.setMinutes(
              eventStartTime.getMinutes() + timezoneOffset + 60
            );

            let eventStartDate = eventStartTime.getDate();

            if (dateIndex[eventStartDate] || dateIndex[eventStartDate] >= 0) {
              dateIndex[eventStartDate] = dateIndex[eventStartDate] + 1;
            } else {
              dateIndex[eventStartDate] = 0;
            }
            eventStartTime.setHours(dateIndex[eventStartDate]);
            event["start"] = eventStartTime;

            if (
              event.report_type === constants.CALENDAR.PUBLISHED_SLUG.COMPANY
            ) {
              event["className"] = ["event", "companyReportEvent"];
            } else if (
              event.report_type === constants.CALENDAR.PUBLISHED_SLUG.BENCHMARK
            ) {
              event["className"] = ["event", "benchmarkEvent"];
            } else if (
              event.report_type ===
              constants.CALENDAR.PUBLISHED_SLUG.MARKET_FORECAST
            ) {
              event["className"] = ["event", "marketForecastEvent"];
            } else if (
              event.report_type ===
              constants.CALENDAR.PUBLISHED_SLUG.MARKET_LANDSCAPE
            ) {
              event["className"] = ["event", "marketLandscapeEvent"];
            } else if (
              event.report_type ===
              constants.CALENDAR.PUBLISHED_SLUG.SPECIAL_REPORT
            ) {
              event["className"] = ["event", "specialReportEvent"];
            } else if (
              event.report_type ===
              constants.CALENDAR.PUBLISHED_SLUG.CUSTOMER_STUDIES
            ) {
              event["className"] = ["event", "customerStudiesEvent"];
            } else if (
              event.report_type === constants.CALENDAR.PUBLISHED_SLUG.ECOSYSTEM
            ) {
              event["className"] = ["event", "ecosystemEvent"];
            }
            return event;
          })
        );

        if (type === "success") {
          const finalData = webinarData.concat(updatedPublishedEvents);
          setAllEvents(finalData);
          setDisplayEvents(finalData);
        } else {
          setAllEvents(updatedPublishedEvents);
          setDisplayEvents(updatedPublishedEvents);
        }
      })
      .catch((error) => {
        Toast(
          error && error.data && error.data.error
            ? error.data.error
            : constants.ERROR.SOMETHING_WENT_WRONG,
          "error"
        );
      });
  };

  const showWebinarEventDetails = (e) => {
    const eventDetail = e.event?._def.extendedProps;
    const host_name = `${eventDetail.host?.first_name} ${eventDetail.host?.last_name}`;
    const host_description = eventDetail.host?.description;
    // const date = eventDetail?.start_date_time.split("T")[0];
    const startTime = eventDetail?.start_date_time.split("T")[1];
    const convertStartTime = convertTime24To12HourFormat(startTime);
    const durationTime = calculateDurationTime(
      eventDetail?.start_date_time,
      eventDetail?.end_date_time
    );
    let fullDate = new Date(e.event?.start).toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setShowFullDate(fullDate);
    setWebinarDurationTime(durationTime);
    setHostName(host_name);
    setHostDescription(host_description);
    setEventTitle(eventDetail.name);
    setEventDescription(eventDetail.description);
    setWebinarLink(eventDetail.event_url);
    setShowEventModalIsOpen(true);
    setPlaceholderStartTime(convertStartTime);
    setEventStatus(eventDetail.status);
  };

  const showPublishedEventDetails = (e) => {
    const eventDetail = e.event?._def.extendedProps;
    const reportType = reportList.find(
      (report) => report.value === eventDetail.report_type
    );
    let fullDate = new Date(e.event?.start).toLocaleDateString("en-us", {
      weekday: "long",
      month: "long",
      day: "numeric",
      year: "numeric",
    });
    setFileName(eventDetail.report_name);
    setDisplayAuthorName(eventDetail.authors ?? "-");
    setDisplayEventTitle(eventDetail.name);
    // This change is made to hide the 'topic'
    // setDisplayEventTopic(eventDetail.topic);
    setDisplayReportType(reportType.label);
    setDisplaySegment(eventDetail.segment_name);
    setShowFullDate(fullDate);
    setDisplayQuater(eventDetail.calendar_quarter);
    setPublishedEventModal(true);
    setEventStatus(eventDetail.status);
    setPublishCalendarObject(eventDetail);
  };

  const upcomingEventRedirection = (selectedUpcomingEvent) => {
    if (selectedUpcomingEvent?.type === "publish_calendar") {
      let fullDate = new Date(
        selectedUpcomingEvent?.published_date
      ).toLocaleDateString("en-us", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });

      const reportTypeMapping = {
        company_report: "Company Research",
        benchmark: "Benchmark",
        market_landscape: "Market Landscape",
        market_forecast: "Market Forecast",
        customer_studies: "Customer Study",
        ecosystem: "Ecosystem",
        special_report: "Special Report",
      };

      const displayReportType =
        reportTypeMapping[selectedUpcomingEvent?.report_type] ||
        selectedUpcomingEvent?.report_type;

      setDisplayAuthorName(selectedUpcomingEvent?.authors ?? "-");
      setFileName(selectedUpcomingEvent?.report_name);
      setDisplayReportType(displayReportType);
      setDisplayEventTitle(selectedUpcomingEvent?.title);
      // This change is made to hide the 'topic'
      // setDisplayEventTopic(selectedUpcomingEvent?.topic);
      setDisplaySegment(selectedUpcomingEvent?.segment_name);
      setShowFullDate(fullDate);
      setDisplayQuater(selectedUpcomingEvent?.calendar_quarter);
      setEventStatus(selectedUpcomingEvent?.status);

      if (selectedUpcomingEvent) {
        setPublishedEventModal(true);
      }
    } else {
      let fullDate = new Date(
        selectedUpcomingEvent?.start_date_time
      ).toLocaleDateString("en-us", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
      const durationTime = calculateDurationTime(
        selectedUpcomingEvent?.start_date_time,
        selectedUpcomingEvent?.end_date_time
      );
      const startTime =
        selectedUpcomingEvent?.start_date_time?.split("T")[1] ?? "";
      const convertStartTime = convertTime24To12HourFormat(startTime);
      const host_name = `${selectedUpcomingEvent?.host?.first_name} ${selectedUpcomingEvent?.host?.last_name}`;
      const host_description = selectedUpcomingEvent?.host?.description;
      setShowFullDate(fullDate);
      setWebinarDurationTime(durationTime);
      setHostName(host_name);
      setHostDescription(host_description);
      setEventTitle(selectedUpcomingEvent?.title);
      setEventDescription(selectedUpcomingEvent?.description);
      setWebinarLink(selectedUpcomingEvent?.event_url);
      setPlaceholderStartTime(convertStartTime);
      setEventStatus(selectedUpcomingEvent?.status);

      if (selectedUpcomingEvent) {
        setShowEventModalIsOpen(true);
      }
    }
  };
  const calculateDurationTime = (startTime, endTime) => {
    const difference = new Date(endTime) - new Date(startTime);
    const differenceInMinutes = difference / 1000 / 60;
    let hours = Math.floor(differenceInMinutes / 60);
    if (hours < 0) {
      hours = 24 + hours;
    }
    let minutes = Math.floor(differenceInMinutes % 60);
    if (minutes < 0) {
      minutes = 60 + minutes;
    }
    let hoursAndMinutes;
    if (hours === 0) {
      hoursAndMinutes =
        "0" + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " minutes";
    } else {
      hoursAndMinutes =
        "0" + hours + ":" + (minutes < 10 ? "0" : "") + minutes + " hours";
    }
    return hoursAndMinutes;
  };

  const convertTime24To12HourFormat = (timeString) => {
    const [hourString, minute] = timeString?.split(":");
    const hour = +hourString % 24;
    return (hour % 12 || 12) + ":" + minute + (hour < 12 ? " AM" : " PM");
  };

  const searchTextResult = (e) => {
    let searchInput = e.target.value;
    setSearchText(searchInput);

    const searchResult = allEvents.filter((event) =>
      event.title.toLowerCase().includes(searchInput.toLowerCase())
    );
    setDisplayEvents(searchResult);
    if (searchInput.length > 0) {
      setActiveView("listYear");
    } else {
      setActiveView("dayGridMonth");
    }
  };

  const changeCalendarView = (viewType) => {
    setActiveView(viewType);
  };

  const redirectToReport = () => {
    let path;
    const reportTypeTransformations = {
      special_report: "special-reports",
      market_forecast: "market-forecast",
      market_landscape: "market-landscape",
      ecosystem: "ecosystems",
      customer_studies: "customer-studies",
    };

    const transformedReportType =
      reportTypeTransformations[publishCalendarObject.report_type] ||
      publishCalendarObject.report_type;

    if (publishCalendarObject.report_type === "company_report") {
      path = `company/${publishCalendarObject.company_id}/dashboard?segment=${publishCalendarObject.segment_id}&content=0`;
    } else {
      path = `${transformedReportType}/${publishCalendarObject.report_id}/dashboard`;
    }
    history.push(path);
  };

  let classNameModalHeader = "published-event-container";
  let borderLeftStyle;
  let colorIconStyle;

  if (eventStatus === "draft") {
    classNameModalHeader += " draftCalendarModalHeaderEvent";
    borderLeftStyle = { borderLeft: "10px solid #555555" };
    colorIconStyle = { color: "#000000" };
  } else {
    const reportTypeToColor = {
      "Company Research": "#5eb37e",
      Benchmark: "#f19e37",
      "Market Forecast": "#69c1fa",
      "Market Landscape": "#d63864",
      "Special Report": "#3577ed",
      "Customer Study": "#832da4",
      Ecosystem: "#FFA1B2",
    };
    const defaultColor = "#1085c6";
    const reportType =
      displayReportType in reportTypeToColor ? displayReportType : "";
    const color = reportTypeToColor[reportType] || defaultColor;
    // if white space or blank space was there in reporttype then removing that
    classNameModalHeader += reportType
      ? ` ${reportType.toLowerCase().replace(/\s+/g, "")}ModalHeader`
      : "";
    borderLeftStyle = { borderLeft: `10px solid ${color}` };
    colorIconStyle = { color };
  }

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white br_8 main_content_height d-flex">
        <div className="webinar-left">
          <CalendarSidebar
            allEvents={allEvents}
            setDisplayEvents={setDisplayEvents}
            displayEvents={displayEvents}
          />
        </div>

        <div className="webinar-right">
          <CalendarHeader
            calendarHeaderDate={calendarHeaderDate}
            activeView={activeView}
            searchText={searchText}
            searchTextResult={searchTextResult}
            changeCalendarView={changeCalendarView}
          />
          <FullCalendar
            plugins={[
              dayGridPlugin,
              timeGridPlugin,
              interactionPlugin,
              listPlugin,
            ]}
            ref={calendarRef}
            initialView="dayGridMonth"
            editable={true}
            dayMaxEvents={4}
            eventClick={(e) => {
              const event = e.event._def.extendedProps;
              if (event.hasOwnProperty("topic")) {
                showPublishedEventDetails(e);
              } else {
                showWebinarEventDetails(e);
              }
            }}
            displayEventEnd={true}
            headerToolbar={false}
            height={"78vh"}
            eventTimeFormat={{
              hour: "numeric",
              minute: "2-digit",
            }}
            events={displayEvents}
            eventDisplay="block"
            views={viewsForCalendar}
          />
        </div>
      </div>
      <div>
        {showEventModalIsOpen && (
          <Modal
            isOpen={showEventModalIsOpen}
            toggle={() => setShowEventModalIsOpen(false)}
            className={"modal-lg modal-w-header custom-modal primary-modal"}
          >
            <ModalHeader>
              <div className="show-event-modal-header">
                <h2 className="">Webinar Event Details</h2>
                <span
                  onClick={() => setShowEventModalIsOpen(false)}
                  style={{
                    color: "#555555",
                    float: "right",
                    cursor: "Pointer",
                  }}
                  className="material-icons"
                >
                  close
                </span>
              </div>
            </ModalHeader>
            <ModalBody>
              <div className="d-flex">
                <span className="material-icons-outlined icon-fs-18">
                  domain_verification
                </span>
                <p className="show-event-modal-text-webinar">{showFullDate}</p>
              </div>
              <div style={{ marginTop: "-8px" }} className="d-flex">
                <span className="material-icons-outlined icon-fs-18">
                  alarm_on
                </span>
                <p className="show-event-modal-text-webinar">{`${placeholderStartTime} Eastern Time`}</p>
              </div>
              <div style={{ marginTop: "-8px" }} className="d-flex">
                <span className="material-icons-outlined icon-fs-18">
                  timelapse
                </span>
                <p className="show-event-modal-text-webinar">
                  {webinarDurationTime}
                </p>
              </div>
              <div className="show-event-modal-head-para">
                <h3 className="overflow-break-word">{eventTitle}</h3>
                <div dangerouslySetInnerHTML={{ __html: eventDescription }} />
              </div>
              <div className="show-event-modal-head-para">
                <div className="d-flex">
                  <span className="material-icons-outlined icon-fs-18">
                    person
                  </span>
                  <h3 className="hosted-text">
                    Hosted By: <span>{hostName}</span>
                  </h3>
                </div>
                {hostDescription && (
                  <p className="ml-1 overflow-break-word">{hostDescription}</p>
                )}
              </div>
              <div>
                <h3 className="join-link-head">Join Link</h3>
                <a
                  className="webinar-link-text"
                  href={webinarLink}
                  target="_blank"
                >
                  {webinarLink}
                </a>
              </div>
            </ModalBody>
          </Modal>
        )}
      </div>
      {/* Publish Calendar Event Modal */}
      {publishedEventModal && (
        <PublishCalendarModal
          isOpen={publishedEventModal}
          toggle={() => setPublishedEventModal(!publishedEventModal)}
          modalHeaderClassName={classNameModalHeader}
          borderLeftStyle={borderLeftStyle}
          colorIconStyle={colorIconStyle}
          eventTitle={displayEventTitle}
          displaySegment={displaySegment}
          displayReportType={displayReportType}
          redirectToReport={redirectToReport}
          reportButtonIcon={go_to_report_btn}
          displayQuater={displayQuater}
          displayAuthorName={displayAuthorName}
          showFullDate={showFullDate}
          // This change is made to hide the 'topic'
          // eventTopic={displayEventTopic}
          onClose={() => {
            history.replace({ ...history.location, state: undefined });
            setPublishedEventModal(false);
            setSelectedUpcomingEvent(null);
          }}
          fileName={fileName}
        />
      )}
    </aside>
  );
};

function mapDispatchToProps(dispatch) {
  return {
    activeMenuTab: (tab) => {
      dispatch(activeMenuTab(tab));
    },
  };
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
    client: state.client,
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(WebinarCalendar);
