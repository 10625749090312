import React, { useEffect, useState, lazy, Suspense } from "react";
import { useLocation } from "react-router-dom";
import CLIENT_USER_MANAGEMENT from "./Utils/constants";
import CustomBreadcrumb from "../../Components/CustomBreadcrumb";
import constants from "../../Utils/constants";
import "./Styles/UserMangement.scss";
import { useDispatch } from "react-redux";
import { activeMenuTab } from "../../Store/Actions/User";
import { UserManagementServices } from "./Services/UserManagementServices";

const ListClientUsers = lazy(() => import("../ListClientUsers"));

const ListMagicLinkUsers = lazy(() =>
  import("../MagicLink/ListMagicLinkUsers")
);

const UserManagement = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  // tabs for showing magic link user and normal user
  const tabs = [
    CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.NORMAL_USER_TAB,
    CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.MAGIC_LINK_USER_TAB,
  ];

  // states for showing count in header
  const [clientCount, setClientCount] = useState({
    total_client_users: null,
    total_magic_link_users: null,
    total_normal_users: null,
  });

  useEffect(() => {
    dispatch(activeMenuTab(constants.APPLICATION_ROUTE.CLIENT_USER.LIST.ROUTE));
    getUsersCount();
  }, []);

  // api for user count on header
  const getUsersCount = async () => {
    try {
      const res = await UserManagementServices.getUsersCount();
      const clientUserCountData = res.data.data;
      setClientCount({
        total_client_users: clientUserCountData.total_client_users,
        total_magic_link_users: clientUserCountData.total_magic_link_users,
        total_normal_users: clientUserCountData.total_normal_users,
      });
    } catch (error) {
      console.error("Error fetching user count:", error);
    }
  };

  // Set the default tab based on location.state
  const [activeTab, setActiveTab] = useState(
    location.state?.selectedTab ||
      CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.NORMAL_USER_TAB
  );

  // function for handling tab change
  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  // Breadcumbs - Title header
  const breadcrumbs = [
    {
      title: CLIENT_USER_MANAGEMENT.BREADCUMB.TITLE,
      link: null,
      is_active: false,
    },
  ];

  return (
    <aside className="main_content">
      <div className="m-3 mb-0 bg-white px-3 py-3 br_8 main_content_height">
        {/* Breadcrumb */}
        <div className="d-flex align-items-center flex-wrap title-section breadcrumb-mb-0 justify-content-between">
          <CustomBreadcrumb breadcrumbs={breadcrumbs} />
          <div className="d-flex gap-10">
            {/* Seat Limit */}
            <div className="tab-btn-2">
              <span
                className="material-icons-round icon-fs-20 mr-1"
                style={{ color: "#0461C2" }}
              >
                star
              </span>
              <span>
                Seat Limit <span>{clientCount.total_client_users}</span>
              </span>
            </div>

            {/* Normal User */}
            <div className="tab-btn-2">
              <span
                className="material-icons-outlined icon-fs-20 mr-2"
                style={{ color: "#0461C2" }}
              >
                group
              </span>
              <span className="fs-14">
                Normal User:{" "}
                <span className="ml-1">{clientCount.total_normal_users}</span>
              </span>
            </div>

            {/* Magic Link User */}
            <div className="tab-btn-2">
              <span
                className="material-symbols-outlined icon-fs-20 mr-2"
                style={{ color: "#0461C2" }}
              >
                book_4_spark
              </span>
              <span className="fs-14">
                Magic Link User:{" "}
                <span className="ml-1">
                  {clientCount.total_magic_link_users}
                </span>
              </span>
            </div>
          </div>
        </div>

        {/* Tabs */}
        <div className="div-1">
          <div className="d-flex g-15">
            {tabs.map((tab) => (
              <button
                key={tab}
                className={`tab-btn ${
                  activeTab === tab ? "active-tab" : "not-active-tab"
                }`}
                onClick={() => handleTabChange(tab)}
              >
                {tab}
              </button>
            ))}
          </div>
        </div>

        {/* Tab Content with Lazy Loading */}
        <div className="magic-users-table">
          <div className="client-users-table-block colored-th-table-block custom-table-block custom-table-block-2 first-col-fixed-block last-col-fixed-block nowrap-table-block th-nowrap sort-table-block center-th-align">
            <Suspense fallback={<div className="mt-10">⏳ Loading...</div>}>
              {activeTab ===
              CLIENT_USER_MANAGEMENT.USER_MANAGEMENT_TAB.NORMAL_USER_TAB ? (
                <ListClientUsers />
              ) : (
                <ListMagicLinkUsers />
              )}
            </Suspense>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default UserManagement;
