import { Axios } from "../../../lib/axios";

const magicLinkRoute = "magic-link/";

const addMagicLinkUser = (body) => {
  return Axios.post(`${magicLinkRoute}user`, body);
};

const editMagicLinkUser = (userId, body) => {
  return Axios.put(`${magicLinkRoute}user/${userId}`, body);
};
const getAllMagicLinkUsers = (queryParams) => {
  return Axios.get(`${magicLinkRoute}user${queryParams}`);
};
const updateUserApiStatus = (user_id, body) => {
  return Axios.patch(`${magicLinkRoute}user/${user_id}/status/${body}`);
};
const deleteMagicLinkUser = (userId) => {
  return Axios.delete(`${magicLinkRoute}user/${userId}`);
};
const magicLinkLogin = (body) => {
  return Axios.post(`${magicLinkRoute}login`, body);
};
const getSpecificMagikLinkUser = (userId) => {
  return Axios.get(`${magicLinkRoute}user/${userId}`);
};
const copyMagicLink = (queryParams) => {
  return Axios.get(`${magicLinkRoute}copy/${queryParams}`);
};
const resendMagicLink = (queryParams) => {
  return Axios.get(`${magicLinkRoute}renewal/${queryParams}`);
};
const renewMagicLinkRequest = (userId) => {
  return Axios.get(`${magicLinkRoute}request/${userId}`);
};
const requestMagicLink = (token) => {
  return Axios.get(`${magicLinkRoute}linkReq/${token}`);
};

export const MagicLinkUserServices = {
  addMagicLinkUser,
  editMagicLinkUser,
  getAllMagicLinkUsers,
  updateUserApiStatus,
  deleteMagicLinkUser,
  getSpecificMagikLinkUser,
  magicLinkLogin,
  copyMagicLink,
  resendMagicLink,
  renewMagicLinkRequest,
  requestMagicLink,
};
