import React, { useState } from "react";
import { Modal, ModalBody, ModalHeader } from "reactstrap";
import "../Styles/calendar.scss";
import "../Styles/ratingform.scss";
import StarRating from "./StarRating";
import decodeJWT from "../lib/decodeJWT";
import { FeedBackService } from "../Services/FeedBack";
import { connect } from "react-redux";
import { getFirstLetters } from "../Helpers/Helper";

function RatingForm(props) {
  const [feedBackJson, setFeedBackJson] = useState({
    overall_rating_experience: 0,
    quantitative_rating_experience: 0,
    qualitative_rating_experience: 0,
    boards_rating: 0,
    search_rating: 0,
    visualization_rating: 0,
    collabrations_rating: 0,
    feedback_quality_of_research: "",
    feedback_quality_of_TBR_models: "",
  });
  let tokenDetails = decodeJWT(localStorage.getItem("token"));

  const [showRatingModel, setShowRatingModel] = useState(true);

  const handleSubmit = async () => {
    try {
      let input = {
        feedback_quality_of_research: feedBackJson.feedback_quality_of_research,
        feedback_quality_of_tbr_models:
          feedBackJson.feedback_quality_of_TBR_models,
        rating_details: JSON.stringify({
          overall_rating_experience: feedBackJson.overall_rating_experience,
          quantitative_rating_experience:
            feedBackJson.quantitative_rating_experience,
          qualitative_rating_experience:
            feedBackJson.qualitative_rating_experience,
          boards_rating: feedBackJson.boards_rating,
          search_rating: feedBackJson.search_rating,
          visualization_rating: feedBackJson.visualization_rating,
          collabrations_rating: feedBackJson.collabrations_rating,
        }),
      };

      await FeedBackService.addFeedBack(input);
      setShowRatingModel(false);
      localStorage.removeItem("show_feedback_form");
    } catch (e) {
      console.error("e", e);
    }
  };

  const handleAskMeLater = async () => {
    try {
      await FeedBackService.askMeLater();
      setShowRatingModel(false);
      localStorage.removeItem("show_feedback_form");
    } catch (e) {
      console.error("e", e);
    }
  };
  return (
    <Modal
      isOpen={showRatingModel}
      // toggle={() => setShowRatingModel(false)}
      style={{ width: "500px" }}
      className={
        "modal-lg modal-w-header custom-modal primary-modal rating-form"
      }
    >
      <ModalHeader>
        <div className="show-event-modal-header rating-header">
          <h2 style={{ fontSize: "18px" }}>
            Share your Experience with the Development Team
          </h2>
          <span
            onClick={() => setShowRatingModel(false)}
            style={{ color: "#555555", float: "right", cursor: "Pointer" }}
            className="material-icons"
          >
            close
          </span>
        </div>
      </ModalHeader>
      <ModalBody>
        <div className="rating-body">
          <div className="d-flex align-items-center">
            <span className="profile_circle">
              {getFirstLetters(
                `${props?.home?.loginUserSuccess?.first_name} ${props?.home?.loginUserSuccess?.last_name}`
              )}
            </span>
            <div className="user-details-block d-none d-md-flex flex-column align-items-start">
              <span>
                {" "}
                {props?.home?.loginUserSuccess?.first_name +
                  " " +
                  props?.home?.loginUserSuccess?.last_name}
              </span>
              <span className="user-role">
                {" "}
                {tokenDetails && tokenDetails.role && (
                  <span className="user-profile-role">
                    {props.home.loginUserSuccess?.designation ||
                      (["Super Admin"].includes(tokenDetails.role.name)
                        ? "Admin"
                        : tokenDetails.role.name)}
                    {tokenDetails.client && tokenDetails.client.name
                      ? ` - ${tokenDetails.client.name}`
                      : ""}
                  </span>
                )}
              </span>
            </div>
          </div>
          <div className="feedback-form-ineer-body">
            <div className="my-2 seperator-line">
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Overall Experience</span>
                <StarRating
                  state={feedBackJson}
                  field="overall_rating_experience"
                  value={feedBackJson.overall_rating_experience}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Quantitative Experience</span>
                <StarRating
                  state={feedBackJson}
                  field="quantitative_rating_experience"
                  value={feedBackJson.quantitative_rating_experience}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Qualitative Experience</span>
                <StarRating
                  state={feedBackJson}
                  field="qualitative_rating_experience"
                  value={feedBackJson.qualitative_rating_experience}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
            </div>
            <div className="my-2 seperator-line">
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Boards</span>
                <StarRating
                  state={feedBackJson}
                  field="boards_rating"
                  value={feedBackJson.boards_rating}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Search</span>
                <StarRating
                  state={feedBackJson}
                  field="search_rating"
                  value={feedBackJson.search_rating}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Visualizations</span>
                <StarRating
                  state={feedBackJson}
                  field="visualization_rating"
                  value={feedBackJson.visualization_rating}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
              <div className="d-flex justify-content-between mb-2 rating-ques">
                <span>Collabrations</span>
                <StarRating
                  state={feedBackJson}
                  field="collabrations_rating"
                  value={feedBackJson.collabrations_rating}
                  setState={setFeedBackJson}
                  fontSize="14px"
                />
              </div>
            </div>
            <div>
              <div className="rating-ques mt-3 mb-2">
                Please share your feedback on the quality of research insights.
              </div>
              <textarea
                value={feedBackJson.feedback_quality_of_research}
                placeholder="Type something here.."
                className="d-flex from-group text-area-feedback-form"
                row="5"
                onChange={(e) =>
                  setFeedBackJson({
                    ...feedBackJson,
                    feedback_quality_of_research: e.target.value,
                  })
                }
              />
            </div>
            <div className="mt-2">
              <div className="rating-ques mt-3 mb-2">
                Please share your feedback on the quality and completeness of
                TBR data models.
              </div>
              <textarea
                value={feedBackJson.feedback_quality_of_TBR_models}
                placeholder="Type something here.."
                className="d-flex from-group text-area-feedback-form"
                row="5"
                onChange={(e) =>
                  setFeedBackJson({
                    ...feedBackJson,
                    feedback_quality_of_TBR_models: e.target.value,
                  })
                }
              />
            </div>
            <div
              className="d-flex justify-content-center"
              style={{ gap: "10px" }}
            >
              <button
                className="btn mt-3 border custom-border"
                style={{ color: "#3F7FE0", fontSize: "14px" }}
                onClick={handleAskMeLater}
              >
                Ask Me Later
              </button>
              <button
                className="btn btn-primary mt-3"
                style={{ fontSize: "14px" }}
                onClick={handleSubmit}
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
}

const mapStateToProps = (state) => {
  return {
    home: state.home,
  };
};
export default connect(mapStateToProps, null)(RatingForm);
