import { DashboardTables } from "../../../Utils/DashboardTables";

export const selectionTab = [
  {
    label: "Company Research",
    value: "company",
    items: [
      {
        label: "Company Research",
        value: "vendor_reports",
      },
      {
        label: "Company Profile",
        value: "vendor_profiles",
      },
      {
        label: "Company Snapshots",
        value: "vendor_snapshot",
      },
      {
        label: "Benchmark Coverage",
        value: "benchmark_coverage",
      },
    ],
  },
  {
    label: "Benchmarks",
    value: "benchmark",
  },
  {
    label: "Market Forecasts",
    value: "market_forecast",
  },
  {
    label: "Market Landscapes",
    value: "market_landscape",
  },
  {
    label: "Special Reports",
    value: "special_report",
  },
  {
    label: "Customer Studies",
    value: "customer_study",
  },
  {
    label: "Ecosystems",
    value: "ecosystem",
  },
];
export const dashboardTopCard = [
  {
    id: 1,
    title: "Company Research",
    isHovered: false,
    sub_type: "company",
    type: "company",
    icon: "meeting_room",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 2,
    title: "Benchmarks",
    isHovered: false,
    type: "benchmark",
    sub_type: "benchmarks",
    icon: "speed",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 3,
    title: "Market Forecasts",
    isHovered: false,
    type: "forecast",
    sub_type: "forecasts",
    icon: "trending_up",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 4,
    title: "Market Landscapes",
    isHovered: false,
    type: "landscape",
    sub_type: "landscapes",
    icon: "analytics",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 5,
    title: "Special Reports",
    isHovered: false,
    type: "special_reports",
    sub_type: "special_report",
    icon: "summarize",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 6,
    title: "Customer Studies",
    isHovered: false,
    type: "customer_study",
    sub_type: "customer_study",
    icon: "portrait",
    view_count: 0,
    total_counts: 0,
  },
  {
    id: 7,
    title: "Ecosystems",
    isHovered: false,
    type: "ecosystem",
    sub_type: "ecosystem",
    icon: "public",
    view_count: 0,
    total_counts: 0,
  },
];
export const boardOptions = [
  { id: 1, type: "my", isOpen: false },
  { id: 2, type: "tbri", isOpen: false },
  { id: 3, type: "shared", isOpen: false },
  { id: 4, type: "enterprise", isOpen: false },
];
export const chartOptions = [
  { id: 1, type: "", isOpen: false },
  { id: 2, type: "tbri", isOpen: false },
  { id: 3, type: "shared", isOpen: false },
  { id: 4, type: "enterprise", isOpen: false },
];
export const regex = /(<([^>]+)>)/gi;
export const whatsNewReportTypes = [
  {
    id: 1,
    name: "All Reports",
    slug: "all",
    is_checked: true,
    table: DashboardTables.tbrAllReportTable,
  },
  {
    id: 3,
    name: "Company Research",
    slug: "company",
    is_checked: false,
    table: DashboardTables.companyTable,
  },
  {
    id: 4,
    name: "Benchmarks",
    slug: "benchmark",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 5,
    name: "Market Forecasts",
    slug: "market_forecast",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 6,
    name: "Market Landscapes",
    slug: "market_landscape",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 7,
    name: "Special Reports",
    slug: "special_report",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 8,
    name: "Customer Studies",
    slug: "customer_study",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
  {
    id: 9,
    name: "Ecosystems",
    slug: "ecosystem",
    is_checked: false,
    table: DashboardTables.reportTable,
  },
];
